import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';

import { RecordedGreetingApprovalStatus } from '@infinitus/generated/frontend-common';
import { LIST_GREETINGS, LIST_GREETING_TEMPLATES } from '@infinitus/graphql';
export type GreetingState = 'MISSING_GREETINGS' | 'PENDING_APPROVAL';

interface Props {
  orgUuid: string;
}

export default function useCheckMyRecordedGreetings({ orgUuid }: Props) {
  const [greetingsState, setGreetingsState] = useState<null | GreetingState>(null);
  const areRecordedGreetingsApproved =
    greetingsState !== 'MISSING_GREETINGS' && greetingsState !== 'PENDING_APPROVAL';
  //poll for greeting templates if greetings are not approved
  const pollInterval = areRecordedGreetingsApproved ? 0 : 3000;

  const { data: listGreetingTemplatesResponse, loading: loadingGreetingTemplates } = useQuery(
    LIST_GREETING_TEMPLATES,
    { variables: { orgUUID: orgUuid }, pollInterval: pollInterval }
  );

  const {
    data: listGreetingsQueryResponse,
    loading: loadingGreetings,
    refetch: refetchRecordedGreetings,
  } = useQuery(LIST_GREETINGS, {
    variables: { orgUUID: orgUuid },
  });

  const loading = loadingGreetingTemplates || loadingGreetings;

  useEffect(() => {
    const greetingTemplates = listGreetingTemplatesResponse?.listGreetingTemplates ?? [];
    const greetings = listGreetingsQueryResponse?.listGreetings ?? [];

    if (loading) {
      setGreetingsState(null);
      return;
    }

    if (greetingTemplates.length > 0) {
      if (greetings.length !== greetingTemplates.length) {
        setGreetingsState('MISSING_GREETINGS');
      } else if (
        greetings.some((g) => g.approvalStatus !== RecordedGreetingApprovalStatus.GREETING_APPROVED)
      ) {
        setGreetingsState('PENDING_APPROVAL');
      } else {
        setGreetingsState(null);
      }
    }
  }, [listGreetingTemplatesResponse, listGreetingsQueryResponse, loading]);

  return { greetingsState, loading, areRecordedGreetingsApproved, refetchRecordedGreetings };
}
