/**
 * The audio device info
 */
export interface Device {
  id: string;
  label: string;
}

/**
 * The initial audio device state.
 */
export const initialState: AudioDeviceState = {
  deviceId: { id: 'default' },
  devices: [],
  devicesHash: '',
  devicesLoading: true,
  error: null,
};

/**
 * The audio device state which.
 */
export interface AudioDeviceState {
  deviceId: { id: string };
  devices: Device[];
  devicesHash: string;
  devicesLoading: boolean;
  error: Error | null;
}

export interface AudioDeviceContextInterface extends AudioDeviceState {
  /**
   * Selects the audio device.
   */
  selectDevice: (deviceId: string) => void;
}
