import React from 'react';

import { UUID } from '@infinitus/types';

import { ConnectionState } from './NexmoClientProvider';
import { EarMuffState, MuteState } from './NexmoClientService';

export enum NexmoAttachRequestSource {
  READY_PAGE = 'READY_PAGE',
  CALL_PAGE = 'CALL_PAGE',
  RETRY_BUTTON = 'RETRY_BUTTON',
  AUTO_RECONNECT = 'AUTO_RECONNECT',
  FAST_TRACK = 'FAST_TRACK',
  FAST_TRACK_RETRY_BUTTON = 'FAST_TRACK_RETRY_BUTTON',
}
export interface NexmoClientAttachProps {
  conversationUuid: UUID;
  deviceId?: string;
  // Will reconnect even if already connected
  force?: boolean;
  muteMicByDefault: boolean;
  orgUuid: UUID;
  outputDeviceId?: string;
  source: NexmoAttachRequestSource;
}

export type NexmoClientWrapper = {
  attach: (options: NexmoClientAttachProps) => Promise<void>;
  changeInputAudioSource: (id: string) => Promise<void>;
  changingMuteState: boolean;
  connectionState: ConnectionState;
  deleteSession: () => Promise<void>;
  earmuffState: EarMuffState;
  maybeLeaveConversation: () => Promise<void>;
  muteState: MuteState;
  nexmoClientIsReady: boolean;
  setEarmuffed: (earmuffed: boolean) => void;
  setMuted: (muted: boolean) => void;
  startNewSession: () => Promise<void>;
};

const NexmoContext = React.createContext<NexmoClientWrapper | null>(null);
export default NexmoContext;
