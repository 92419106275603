import { AppName } from '@infinitusai/api';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import crypto from 'crypto';
import { getAuth } from 'firebase/auth';

import { REQUEST_ID_HEADER_FIELD } from '@infinitus/apollo/headers';
import { ClientEvent } from '@infinitus/generated/frontend-common';
import { logAPIRequest, logError, APIRequestEvent } from '@infinitus/hooks/useLogBuffer';
import { infinitusai } from '@infinitus/proto/pbjs';
import { UUID } from '@infinitus/types';
import { startCorrelatingLogs } from '@infinitus/utils/logCorrelator';

import { BACKEND_SERVER_URL, FRONTEND_VERSION } from './constants';

interface apiWithConfigArgs {
  contentType?: AxiosRequestHeaders['Content-Type'];
  requestId?: string | null;
  responseType?: AxiosRequestConfig['responseType'];
}

// Exported for testing only
export abstract class BaseApi {
  protected _appName: AppName;
  protected _apiInstance: AxiosInstance;

  constructor(appName: AppName) {
    this._appName = appName;
    this._apiInstance = axios.create({
      baseURL: BACKEND_SERVER_URL,
      headers: {
        'X-INF-FRONTEND-VERSION': FRONTEND_VERSION,
        'X-INF-APP-NAME': appName,
      },
    });
    // Log API request received in correlated log trace
    this._apiInstance.interceptors.request.use(
      (request) => {
        return this.correlateAxiosRequest(request);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // Log API responses received in correlated log trace
    this._apiInstance.interceptors.response.use(
      (response) => {
        this.correlateAxiosResponse(response);
        try {
          this.logResponseToBigQuery(response, true);
        } catch (e: any) {
          this.logResponseToBigQuery(e.response, false);
          console.error(e);
        }
        return response;
      },
      (error) => {
        this.logResponseToBigQuery(error.response, false);
        // If a useful message is returned, override the default message
        if (
          typeof error?.response?.data === 'string' &&
          error?.response?.data !== 'Internal Server Error'
        ) {
          error['message'] = error?.response?.data;
        }
        return Promise.reject(error);
      }
    );
  }

  // assume API is mounted on same host and port, different subroute.
  // If 'X-INF-ORG-UUID' is not required, orgUuid can be empty
  protected async apiWithAuth(orgUuid: UUID) {
    try {
      const firebaseAuth = await getFirebaseAuth();
      this._apiInstance.defaults.headers.common['Authorization'] = firebaseAuth ?? '';
    } catch (e) {
      logError(`API: Failed to get Firebase Auth: ${e}`);
    }
    this._apiInstance.defaults.headers.common['X-INF-ORG-UUID'] = orgUuid;

    return this._apiInstance;
  }

  protected apiWithConfig({
    contentType,
    responseType,
    requestId,
  }: apiWithConfigArgs): AxiosRequestConfig<undefined> {
    const requestIdValue = requestId || getRequestId();

    let config: AxiosRequestConfig<undefined> = {};

    if (responseType) {
      config.responseType = responseType;
    }

    // Add Headers
    config.headers = {};
    config.headers[REQUEST_ID_HEADER_FIELD] = requestIdValue;
    config.headers['Content-Type'] = contentType;

    return config;
  }

  protected logResponseToBigQuery(response: AxiosResponse, success: boolean) {
    // the method name is of format '/methodname'.
    // we want to strip the '/' and return the remaining methodname
    const endpoint = response?.config['url']?.substring(1) || 'UNKNOWN';
    const requestId = response?.headers['x-inf-request-id'];

    const apiEvent: APIRequestEvent = {
      endpoint,
      requestId,
      success,
    };
    logAPIRequest(apiEvent);
  }

  // correlateAxiosRequest will log the dispatch of the request and may mutate the
  // request object with a new requestId if one does not already exist. The
  // mutated object will also be returned.
  protected correlateAxiosRequest(request: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    const requestIdFound = request.headers[REQUEST_ID_HEADER_FIELD];
    const { requestId, correlatedLog } = startCorrelatingLogs({ requestId: requestIdFound });
    request.headers[REQUEST_ID_HEADER_FIELD] = requestId;
    correlatedLog(`Request ${request.url || 'unknown url'}`);

    return request;
  }

  // correlateAxiosResponse will log receiving the request.
  protected correlateAxiosResponse(response: AxiosResponse): AxiosResponse {
    const endpoint = response?.config['url']?.substring(1) || 'unknown url';
    const requestId = response?.headers['x-inf-request-id'];
    if (requestId) {
      const { correlatedLog } = startCorrelatingLogs({ requestId });
      correlatedLog(`Successfully Received ${endpoint}`);
    }

    return response;
  }
}

class OperatorPortalApi extends BaseApi {
  private _registerOperatorController: AbortController | undefined;

  constructor(appName = AppName.OPERATOR) {
    super(appName);
  }

  async issueNexmoJwt(requestId: string | null) {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/nexmoJwt', config);
  }

  async joinNexmoConversation(
    requestId: string | null,
    orgUuid: UUID,
    conversationUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.JoinNexmoConversationResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.JoinNexmoConversationRequest.fromObject({
      conversationUuid,
    });
    return api.post('/v2/joinNexmoConversation', body, config);
  }
  async getMyLegInNexmoConversation(
    requestId: string | null,
    orgUuid: UUID,
    conversationUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.GetMyLegInNexmoConversationResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.GetMyLegInNexmoConversationRequest.fromObject({
      conversationUuid,
    });
    return api.post('/v2/getMyLegInNexmoConversation', body, config);
  }

  async getCsvReports(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetCSVReportsRequest,
    abortController?: AbortController | undefined
  ): Promise<AxiosResponse<infinitusai.be.GetCSVReportsResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getCSVReports', body.toJSON(), {
      ...config,
      signal: abortController?.signal,
    });
  }
  async downloadCsv(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.DownloadCSVRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/downloadCSV', body.toJSON(), config);
  }
  async generateCSV(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GenerateCSVRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/generateCSV', body.toJSON(), config);
  }
  async generateBillingReport(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GenerateBillingReportRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/generateBillingReport', body.toJSON(), config);
  }
  async getBillingReports(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetBillingReportsRequest,
    abortController?: AbortController | undefined
  ): Promise<AxiosResponse<infinitusai.be.GetBillingReportsResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return await api.post('/v2/getBillingReports', body.toJSON(), {
      ...config,
      signal: abortController?.signal,
    });
  }
  async generateBillingReportURL(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetBillingReportURLRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/generateBillingReportURL', body.toJSON(), config);
  }
  async hangup(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.HangupResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.HangupRequest.fromObject({
      taskUuid,
      callUuid,
    });
    return api.post('/v2/hangup', body, config);
  }
  async sendCallBackToQueue(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    useRecordedGreeting: boolean
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.ReinstateHeadlessRequest.fromObject({
      taskUuid,
      callUuid,
      useRecordedGreeting,
    });
    return api.post('/v2/reinstateHeadless', body, config);
  }
  async uploadBillingReport(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.UploadBillingReportRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post<
      infinitusai.be.IUploadBillingReportRequest,
      AxiosResponse<infinitusai.be.IUploadBillingReportResponse>
    >('/v2/uploadBillingReport', body.toJSON(), config);
  }

  //TODO: not v1
  async reportIssue(requestId: string | null, orgUuid: UUID, formData: any) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId, contentType: 'multipart/form-data' });
    return api.post('/v2/reportIssue', formData, config);
  }
  async claimReview(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.ClaimReviewResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    let response: AxiosResponse<infinitusai.be.ClaimReviewResponse>;
    try {
      response = await api.post('/v2/claimReview', { taskUuid, callUuid }, config);
    } catch (error) {
      throw error; // rethrow error
    }

    return response;
  }
  async relinquishReview(requestId: string | null, orgUuid: UUID, taskUuid: UUID, callUuid: UUID) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/relinquishReview', { taskUuid, callUuid }, config);
  }
  async unReviewTask(requestId: string | null, orgUuid: UUID, taskUuid: UUID) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/unReviewTask', { taskUuid }, config);
  }
  async updateCallAutoRespondSetting(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    autoRespond: boolean
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CallMonitorUpdateRequest.fromObject({
      taskUuid,
      callUuid,
      autoRespondIvrV2: autoRespond
        ? infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.TRUE
        : infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.FALSE,
    });
    return api.post('/v2/updateCallMonitor', body, config);
  }
  async updateCallRecordingEnabledSetting(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    recordingEnabled: boolean
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CallMonitorUpdateRequest.fromObject({
      taskUuid,
      callUuid,
      recordingEnabled: recordingEnabled
        ? infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.TRUE
        : infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.FALSE,
    });
    return api.post('/v2/updateCallMonitor', body, config);
  }
  async executeAction(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.FeExecuteActionRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post(`/v2/executeAction`, body, config);
  }
  async logAuditEvent(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.AuditLogRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/logAuditEvent', body, config);
  }
  async getCallInputs(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.PortalGetCallInputsRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getCallInputs', body, config);
  }
  async getCustomerTask(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetTaskRequest
  ): Promise<AxiosResponse<infinitusai.be.GetTaskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getTask', body.toJSON(), config);
  }

  async getPreviousOutputs(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetTaskRequest
  ): Promise<AxiosResponse<infinitusai.be.GetTaskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getPreviousOutputs', body.toJSON(), config);
  }
  // submit actions
  async markCallReadyForReview(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    selectiveReviewCallCriteriaMet: boolean,
    additionalRecordingUuids?: UUID[]
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.MarkReadyForReviewRequest.fromObject({
      taskUuid,
      callUuid,
      selectiveReviewCallCriteriaMet,
      additionalRecordingUuids,
    });
    return api.post('/v2/markReadyForReview', body, config);
  }
  async completeCallReview(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    stitchAdditionalCallUuids: UUID[]
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CompleteReviewRequest.fromObject({
      taskUuid,
      stitchAdditionalCallUuids,
    });
    return api.post('/v2/completeReview', body, config);
  }
  async markOperatorUnavailable(
    requestId: string | null,
    body: infinitusai.be.MarkOperatorUnavailableRequest
  ) {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/markOperatorUnavailable', body.toJSON(), config);
  }
  async updateTaskFailureReason(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    failureReason?: infinitusai.be.TaskFailReason.Type
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const failureReasonChosen = failureReason
      ? infinitusai.be.TaskFailReason.fromObject({
          type: failureReason,
        })
      : null;
    const body = infinitusai.be.UpdateTaskFailureReasonRequest.fromObject({
      taskUuid,
      failureReasonChosen,
    });
    return api.post('/v2/updateTaskFailureReason', body, config);
  }
  async requeueTask(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    requeueReason: infinitusai.be.RequeueReason.Type,
    requeueNotes?: string,
    newPhoneNumber?: string
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.RequeueTaskRequest.fromObject({
      taskUuid,
      callUuid,
      requeueReasonChosen: {
        type: requeueReason,
      },
      requeueNotes,
      newPhoneNumber,
    });
    return api.post('/v2/requeueTask', body, config);
  }
  async failTask(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    failReason: infinitusai.be.TaskFailReason.Type
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.FailTaskRequest.fromObject({
      callUuid,
      taskUuid,
      failReason: {
        type: failReason,
      },
    });
    return api.post('/v2/failTask', body, config);
  }
  async countNextTasksForCall(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CountNextTasksForCallRequest.fromObject({
      orgUuid,
      taskUuid,
      callUuid,
    });
    return api.post<
      infinitusai.be.CountNextTasksForCallRequest,
      AxiosResponse<infinitusai.be.ICountNextTasksForCallResponse>
    >('/v2/countNextTasksForCall', body, config);
  }
  async addTaskToCall(requestId: string | null, orgUuid: UUID, taskUuid: UUID, callUuid: UUID) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.AddTaskToCallRequest.fromObject({
      orgUuid,
      taskUuid,
      callUuid,
    });
    return api.post('/v2/addTaskToCall', body, config);
  }
  async switchTasksInCall(requestId: string | null, orgUuid: UUID, taskUuid: UUID, callUuid: UUID) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.SwitchTasksInCallRequest.fromObject({
      orgUuid,
      taskUuid,
      callUuid,
    });
    return api.post('/v2/switchTasksInCall', body, config);
  }
  async getMultiBenefitGroup(
    requestId: string | null,
    taskUuid: UUID,
    orgUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.GetMultiBenefitTasksResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.GetMultiBenefitTasksRequest.fromObject({
      taskUuid,
    });
    return api.post('/v2/getMultiBenefitTasks', body, config);
  }
  async getBundledCustomerTasks(
    requestId: string | null,
    taskUuid: UUID,
    orgUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.GetBundledCustomerTasksResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.GetBundledCustomerTasksRequest.fromObject({
      taskUuid,
    });
    return api.post('/v2/getBundledCustomerTasks', body, config);
  }
  async endTaskInCall(
    requestId: string | null,
    taskUuid: UUID,
    orgUuid: UUID,
    callUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.EndTaskInCallResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.EndTaskInCallRequest.fromObject({
      orgUuid,
      taskUuid,
      callUuid,
    });
    return api.post('/v2/endTaskInCall', body, config);
  }

  async removeMultiBenefitTask(
    requestId: string | null,
    taskUuid: UUID,
    orgUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.MultiBenefitTasksResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.MultiBenefitTasksRequest.fromObject({
      taskUuid,
    });
    return api.post('/v2/removeMultiBenefitTask', body, config);
  }

  async updatePotentialAdverseEvent(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    hasPotentialAdverseEvent: boolean | null,
    potentialAdverseEventNotes: string
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.SavePotentialAdverseEventRequest.fromObject({
      taskUuid,
      callUuid,
      potentialAdverseEventNotes,
      ...(typeof hasPotentialAdverseEvent === 'boolean' && {
        hasPotentialAdverseEvent: {
          value: hasPotentialAdverseEvent,
        },
      }),
    });
    return api.post('/v2/savePotentialAdverseEvent', body, config);
  }
  async createCustomerTask(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.CreateTaskRequest
  ): Promise<AxiosResponse<infinitusai.be.CreateTaskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/createTask', body.toJSON(), config);
  }
  async createCallFromTask(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.CreateCallFromTaskRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/createCallFromTask', body, config);
  }
  async cancelCustomerTask(requestId: string | null, orgUuid: UUID, taskUuid: UUID) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CancelTaskRequest.create({
      taskUuid,
    });
    return api.post('/v2/cancelCustomerTask', body.toJSON(), config);
  }
  async logFrontendError(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.LogFrontendErrorRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/logFrontendError', body.toJSON(), config);
  }
  async latestVersion(requestId: string | null, body: infinitusai.be.FrontendVersionRequest) {
    const config = this.apiWithConfig({ requestId });
    return this._apiInstance.post('/latestVersion', body.toJSON(), config);
  }

  // Dashboard
  async queryCallEventsFromBigquery(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.QueryCallEventsTableRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/queryCallEventsFromBigquery', body.toJSON(), config);
  }
  async queryUserProductivityMetrics(
    requestId: string | null,
    body: infinitusai.be.QueryUserProductivityMetricsTableRequest
  ): Promise<AxiosResponse<infinitusai.be.QueryUserProductivityMetricsTableResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/dashboard/userProductivityMetrics', body.toJSON(), config);
  }
  async queryUserPastWeekSkillMetrics(
    requestId: string | null
  ): Promise<AxiosResponse<infinitusai.be.QueryUserPastWeekSkillMetricsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.QueryUserPastWeekSkillMetricsRequest.fromObject({});
    return api.post('/v2/dashboard/userPastWeekSkillMetrics', body.toJSON(), config);
  }
  async queryUserAchievements(
    requestId: string | null
  ): Promise<AxiosResponse<infinitusai.be.QueryUserAchievementsReqponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.QueryUserAchievementsRequest.fromObject({});
    return api.post('/v2/dashboard/userAchievements', body.toJSON(), config);
  }

  async queryMyRecentCallActivity(
    requestId: string | null,
    body: infinitusai.be.MyRecentCallActivityRequest
  ): Promise<AxiosResponse<infinitusai.be.MyRecentCallActivityResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/dashboard/myRecentCallActivity', body.toJSON(), config);
  }

  async reportCallFeedback(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.ReportCallFeedbackRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/reportCallFeedback', body, config);
  }
  async publishFeedbackToChat(requestId: string | null, orgUuid: UUID, body: any) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/publishFeedbackToChat', body, config);
  }
  async registerOperator(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.RegisterOperatorRequest
  ) {
    this._registerOperatorController = new AbortController();
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/registerOperator', body, {
      ...config,
      signal: this._registerOperatorController.signal,
    });
  }
  // Allows us to stop any pending registerOperator that hasn't yet completed
  cancelRegisterOperatorRequest() {
    if (this._registerOperatorController) this._registerOperatorController.abort();
  }
  async isCallCompleted(requestId: string | null, orgUuid: UUID, taskUuid: UUID, callUuid: UUID) {
    const body = infinitusai.be.IsCallCompletedRequest.create({
      taskUuid,
      callUuid,
    });
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/isCallCompleted', body, config);
  }
  // Reports when the user has interacted with a notification
  async notificationReportClick(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.NotificationReportClickRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/notifications/reportClick', body, config);
  }
  // Gets all the pending notifications for a user from the backend
  async getPendingNotifications(requestId: string | null) {
    const body = infinitusai.be.PendingNotificationRequest.create({});
    // Note: empty string is used for orgUuid, because orgUuid is ignored in
    // the auth middleware
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/notifications/pendingNotifications', body, config);
  }
  // Sends adhoc notifications to specified recipients
  async broadcastNotification(
    requestId: string | null,
    body: infinitusai.be.BroadcastNotificationRequest
  ) {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/notifications/broadcast', body, config);
  }
  async speedTestLatency(requestId: string | null, performanceId: string) {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    const queryParams = performanceId ? `?performanceId=${performanceId}` : '';
    return api.post(`/speedTest/latency${queryParams}`, {}, config);
  }
  async speedTestDownload(
    requestId: string | null,
    body: infinitusai.be.SpeedTestDownloadRequest,
    performanceId: string
  ) {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId, responseType: 'blob' });
    const queryParams = performanceId ? `?performanceId=${performanceId}` : '';
    return api.post(`/speedTest/download${queryParams}`, body.toJSON(), config);
  }
  async speedTestUpload(requestId: string | null, data: Blob, performanceId: string) {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    const queryParams = performanceId ? `?performanceId=${performanceId}` : '';
    return api.post(`/speedTest/upload${queryParams}`, data, config);
  }
  // Convert to operator trainer task
  async convertToOperatorTrainer(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.ConvertToOperatorTrainerTaskRequest
  ): Promise<AxiosResponse<infinitusai.be.ConvertToOperatorTrainerTaskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/convertToOperatorTrainerTask', body.toJSON(), config);
  }
  // Get operator trainer scriptIDs
  async getOperatorTrainerScriptIDs(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetOperatorTrainerScriptIDRequest
  ): Promise<AxiosResponse<infinitusai.be.GetOperatorTrainerScriptIDResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getAvailableOperatorTrainerScriptIDs', body.toJSON(), config);
  }
  // Get operator trainer script
  async getOperatorTrainerScript(
    requestId: string | null,
    body: infinitusai.be.GetOperatorTrainerScriptRequest
  ): Promise<AxiosResponse<infinitusai.be.GetOperatorTrainerScriptResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getOperatorTrainerScript', body.toJSON(), config);
  }
  // Upload CSV For Operator Trainer Script
  async uploadCsvOperatorTrainerScript(
    requestId: string | null,
    body: infinitusai.be.UploadCsvOperatorTrainerScriptRequest
  ): Promise<AxiosResponse<infinitusai.be.UploadCsvOperatorTrainerScriptResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/uploadCsvOperatorTrainerScript', body, config);
  }
  // Update Operator Trainer Call Output Bags
  async updateOperatorTrainerCallOutputBags(
    requestId: string | null,
    body: infinitusai.be.UpdateOperatorTrainerCallOutputBagsRequest
  ): Promise<AxiosResponse<infinitusai.be.UpdateOperatorTrainerCallOutputBagsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/updateOperatorTrainerCallOutputBags', body, config);
  }
  // Delete Operator Trainer Script
  async deleteOperatorTrainerScript(
    requestId: string | null,
    body: infinitusai.be.DeleteOperatorTrainerScriptRequest
  ): Promise<AxiosResponse<infinitusai.be.DeleteOperatorTrainerScriptResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/deleteOperatorTrainerScript', body, config);
  }
  async currentUserActivity(
    requestId: string | null,
    orgUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.CurrentUserActivityResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CurrentUserActivityRequest.fromObject({});
    return api.post('/v2/currentUserActivity', body, config);
  }
  async getOperatorWorkingStates(
    requestId: string | null,
    body: infinitusai.be.GetOperatorWorkingStatesRequest
  ): Promise<AxiosResponse<infinitusai.be.GetOperatorWorkingStatesResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getOperatorWorkingStates', body, config);
  }
  async copyCallOutputs(
    requestId: string | null,
    body: infinitusai.be.CopyCallOutputsRequest
  ): Promise<AxiosResponse<infinitusai.be.CopyCallOutputsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/copyCallOutputs', body, config);
  }
  // TODO: Switch to actual proto REST request
  async logClientEvents(clientEvents: ClientEvent[]): Promise<unknown> {
    const api = await this.apiWithAuth('');
    return api.post('/v2/this.graphql', {
      operationName: 'LogClientEvents',
      query:
        'mutation LogClientEvents($clientEvents: [ClientEvent!]!) {\n  logClientEvents(clientEvents: $clientEvents)\n}',
      variables: {
        clientEvents,
      },
    });
  }
  async getAllSkills(
    requestId: string | null
  ): Promise<AxiosResponse<infinitusai.be.GetAllSkillsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.GetAllSkillsRequest.create({});
    return api.post('/v2/getAllSkills', body, config);
  }
  async getAttributesForSkills(
    requestId: string | null,
    body: infinitusai.be.GetAttributesForSkillsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetAttributesForSkillsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getAttributesForSkills', body, config);
  }
  async deleteSkill(
    requestId: string | null,
    body: infinitusai.be.DeleteSkillRequest
  ): Promise<AxiosResponse<infinitusai.be.DeleteSkillResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/deleteSkill', body, config);
  }
  async upsertSkills(
    requestId: string | null,
    body: infinitusai.be.UpsertSkillsRequest
  ): Promise<AxiosResponse<infinitusai.be.UpsertSkillsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertSkills', body, config);
  }
  async upsertSkillAttributes(
    requestId: string | null,
    body: infinitusai.be.UpsertSkillAttributesRequest
  ): Promise<AxiosResponse<infinitusai.be.UpsertSkillAttributesResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertSkillAttributes', body, config);
  }
  async deleteSkillAttributes(
    requestId: string | null,
    body: infinitusai.be.DeleteSkillAttributesRequest
  ): Promise<AxiosResponse<infinitusai.be.DeleteSkillAttributesResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/deleteSkillAttributes', body, config);
  }
  async getSkillsForOperators(
    requestId: string | null,
    body: infinitusai.be.GetSkillsForOperatorsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetSkillsForOperatorsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getSkillsForOperators', body, config);
  }
  async getSkillsForTask(
    requestId: string | null,
    orgUUID: UUID,
    body: infinitusai.be.GetSkillsForTaskRequest
  ): Promise<AxiosResponse<infinitusai.be.GetSkillsForTaskResponse>> {
    const api = await this.apiWithAuth(orgUUID);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getSkillsForTask', body, config);
  }
  async getMySkills(
    requestId: string | null
  ): Promise<AxiosResponse<infinitusai.be.GetMySkillsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    const body = new infinitusai.be.GetMySkillsRequest();
    return api.post('/v2/getMySkills', body, config);
  }
  async upsertOperatorSkills(
    requestId: string | null,
    body: infinitusai.be.UpsertOperatorSkillRequest
  ): Promise<AxiosResponse<infinitusai.be.UpsertOperatorSkillResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertOperatorSkills', body, config);
  }
  async deleteOperatorSkills(
    requestId: string | null,
    body: infinitusai.be.DeleteOperatorSkillsRequest
  ): Promise<AxiosResponse<infinitusai.be.DeleteOperatorSkillsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/deleteOperatorSkills', body, config);
  }
  async getAllQueues(
    requestId: string | null
  ): Promise<AxiosResponse<infinitusai.be.GetAllQueuesResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    const body = new infinitusai.be.GetAllQueuesRequest();
    return api.post('/v2/getAllQueues', body.toJSON(), config);
  }
  async upsertQueue(
    requestId: string | null,
    body: infinitusai.be.UpsertQueueRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertQueue', body, config);
  }
  async deleteQueue(
    requestId: string | null,
    body: infinitusai.be.DeleteQueueRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/deleteQueue', body, config);
  }
  async getQueueConfig(
    requestId: string | null,
    body: infinitusai.be.GetQueueConfigRequest
  ): Promise<AxiosResponse<infinitusai.be.IGetQueueConfigResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getQueueConfig', body.toJSON(), config);
  }
  async upsertQueueConfig(
    requestId: string | null,
    body: infinitusai.be.UpsertQueueConfigRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertQueueConfig', body.toJSON(), config);
  }

  async getAllTags(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.tags.GetTagsRequest
  ): Promise<AxiosResponse<infinitusai.tags.GetTagsResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getAllTags', body.toJSON(), config);
  }

  async createTag(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.tags.CreateTagRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/createTag', body.toJSON(), config);
  }

  async updateTag(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.tags.UpdateTagRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/updateTag', body.toJSON(), config);
  }

  async deleteTag(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.tags.DeleteTagRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/deleteTag', body.toJSON(), config);
  }

  async tagTask(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.tags.TagTaskRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/tagTask', body.toJSON(), config);
  }

  async tasksAndTags(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.tags.TaggedTasksAndTagsRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/tasksAndTags', body.toJSON(), config);
  }

  async getElevenLabsConfig(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.ElevenLabsConfigForATaskRequest
  ): Promise<AxiosResponse<infinitusai.be.ElevenLabsConfigForATaskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getElevenLabsConfig', body.toJSON(), config);
  }

  async getROIInputs(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetROIInputsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetROIInputsResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getROIInputs', body.toJSON(), config);
  }

  async createROIInput(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.CreateROIInputRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/createROIInput', body.toJSON(), config);
  }

  async redirectAfterProcessCall(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.RedirectAfterProcessCallResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.RedirectAfterProcessCallRequest.fromObject({
      taskUuid,
      callUuid,
    });
    return api.post('/v2/redirectAfterProcessCall', body, config);
  }

  async redirectInCallPhaseHold(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.RedirectInCallPhaseHoldRequest
  ): Promise<AxiosResponse<infinitusai.be.RedirectInCallPhaseHoldResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/redirectInCallPhaseHold', body.toJSON(), config);
  }

  async updateROIInput(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.UpdateROIInputRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/updateROIInput', body.toJSON(), config);
  }

  async deleteROIInput(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.DeleteROIInputRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/deleteROIInput', body.toJSON(), config);
  }

  async getEbvConfigs(
    requestId: string | null
  ): Promise<AxiosResponse<infinitusai.be.GetEbvConfigurationsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post(
      '/v2/getEbvConfigs',
      infinitusai.be.GetEbvConfigurationsRequest.fromObject({}).toJSON(),
      config
    );
  }

  async updateEbvConfig(
    requestId: string | null,
    body: infinitusai.be.UpdateEbvConfigurationRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/updateEbvConfig', body.toJSON(), config);
  }

  async getKGRules(
    requestId: string | null,
    body: infinitusai.be.GetKGRulesRequest
  ): Promise<AxiosResponse<infinitusai.be.GetKGRulesResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getKGRules', body.toJSON(), config);
  }

  async getPendingKGRules(
    requestId: string | null,
    body: infinitusai.be.GetKGRulesRequest
  ): Promise<AxiosResponse<infinitusai.be.GetPendingKGRulesResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getPendingKGRules', body.toJSON(), config);
  }

  async updateKGRules(
    requestId: string | null,
    body: infinitusai.be.UpdateKGRulesRequest
  ): Promise<AxiosResponse> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/updateKGRules', body.toJSON(), config);
  }

  async getAuditLogs(
    requestId: string | null,
    body: infinitusai.be.GetAuditLogsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetAuditLogsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getAuditLogs', body.toJSON(), config);
  }

  async getPayerOrgEnablements(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetPayerOrgEnablementsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetPayerOrgEnablementsResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getPayerOrgEnablements', body.toJSON(), config);
  }

  // OutputMapping endpoints
  async getApiOutputs(
    requestId: string | null,
    body: infinitusai.be.GetCSVSpecRequest
  ): Promise<AxiosResponse<infinitusai.be.GetCSVSpecResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getApiOutputs', body.toJSON(), config);
  }

  async getOrgApiOutputConfigs(
    requestId: string | null,
    body: infinitusai.be.GetOrgApiOutputConfigsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetOrgApiOutputConfigsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getOrgApiOutputConfigs', body.toJSON(), config);
  }

  async batchUpdateOrgApiOutputConfigs(
    requestId: string | null,
    body: infinitusai.be.BatchUpdateOrgApiOutputConfigsRequest
  ): Promise<AxiosResponse<infinitusai.be.BatchUpdateOrgApiOutputConfigsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/batchUpdateOrgApiOutputConfigs', body.toJSON(), config);
  }

  async chatSOP(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.ChatSOPRequest
  ): Promise<AxiosResponse<infinitusai.be.ChatSOPResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/chatSOP', body.toJSON(), config);
  }

  async infiniAsk(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.InfiniAskRequest
  ): Promise<AxiosResponse<infinitusai.be.InfiniAskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/infiniAsk', body.toJSON(), config);
  }

  async getStandardOutputs(
    requestId: string | null,
    body: infinitusai.be.GetStandardOutputsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetStandardOutputsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getStandardOutputs', body.toJSON(), config);
  }

  async upsertStandardOutput(
    requestId: string | null,
    body: infinitusai.be.UpsertStandardOutputRequest
  ): Promise<AxiosResponse<infinitusai.be.UpsertStandardOutputResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertStandardOutput', body.toJSON(), config);
  }

  async getStandardConditions(
    requestId: string | null,
    body: infinitusai.be.GetStandardConditionsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetStandardConditionsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getStandardConditions', body, config);
  }

  async upsertStandardCondition(
    requestId: string | null,
    body: infinitusai.be.UpsertStandardConditionRequest
  ): Promise<AxiosResponse<infinitusai.be.UpsertStandardConditionResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertStandardCondition', body, config);
  }

  async getStandardFunctions(
    requestId: string | null,
    body: infinitusai.be.GetFunctionsRequirementsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetFunctionsRequirementsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getStandardFunctions', body, config);
  }

  async getStandardUtterances(
    requestId: string | null,
    body: infinitusai.be.GetStandardUtterancesRequest
  ): Promise<AxiosResponse<infinitusai.be.GetStandardUtterancesResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getStandardUtterances', body, config);
  }

  async upsertExpandedActionToUtteranceMapping(
    requestId: string | null,
    body: infinitusai.be.UpsertExpandedActionToUtteranceMappingRequest
  ): Promise<AxiosResponse<infinitusai.be.UpsertExpandedActionToUtteranceMappingResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/upsertExpandedActionToUtteranceMapping', body, config);
  }

  async getExpandedActionToUtteranceMappings(
    requestId: string | null,
    body: infinitusai.be.GetExpandedActionToUtteranceMappingsRequest
  ): Promise<AxiosResponse<infinitusai.be.GetExpandedActionToUtteranceMappingsResponse>> {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/v2/getExpandedActionToUtteranceMappings', body, config);
  }
}

// getRequestId will generate a random 20 character id
export function getRequestId() {
  return crypto.randomBytes(10).toString('hex');
}

export const getFirebaseAuth = async () => {
  const currentUser = getAuth().currentUser;
  if (!currentUser) {
    console.warn('No current firebase user! Requests will not have authorization.');
    return undefined;
  }
  return currentUser.getIdToken();
};

export const getOperatorEmail = async () => {
  const currentUser = getAuth().currentUser;
  if (!currentUser) {
    console.warn('No current firebase user! Requests will not have operator email.');
    return undefined;
  }
  return currentUser.email;
};

export const getApiError = (err: unknown): unknown | Error => {
  if (!axios.isAxiosError(err)) {
    return err;
  }

  if (typeof err.response?.data !== 'string') {
    return err;
  }

  return new Error(err.response?.data);
};

// The FastTrackApi is a superset of the Operator Portal API
// We wish to re-use the same functions, but with a different application name
// and also add a few FastTrack-specific ones (that are not in the Operator Portal API)
class FastTrackApi extends OperatorPortalApi {
  constructor() {
    super(AppName.FASTTRACK);
  }

  // Customer Portal
  async getPrivateOrganization(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetPrivateOrganizationRequest
  ): Promise<AxiosResponse<infinitusai.be.GetPrivateOrganizationResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/c/v2/getOrganization', body.toJSON(), config);
  }
  async createCall(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.CreateFastTrackTaskAndCallRequest
  ): Promise<AxiosResponse<infinitusai.be.CreateFastTrackTaskAndCallResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/createFastTrackTaskAndCall', body.toJSON(), config);
  }
  async createFastTrackTask(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.CreateFastTrackTaskAndCallRequest
  ): Promise<AxiosResponse<infinitusai.be.CreateFastTrackTaskAndCallResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/createTask', body.toJSON(), config);
  }
  async createFastTrackCallFromTask(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.CreateFastTrackCallFromTaskRequest
  ): Promise<AxiosResponse<infinitusai.be.CreateFastTrackCallFromTaskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/createCall', body.toJSON(), config);
  }
  async editTask(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.UpdateFastTrackTaskRequest
  ): Promise<AxiosResponse<infinitusai.be.UpdateFastTrackTaskResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/editTask', body.toJSON(), config);
  }
  async getEstimatedHoldTime(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.GetEstimatedHoldTimeRequest
  ): Promise<AxiosResponse<infinitusai.be.GetEstimatedHoldTimeResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/getEstimatedHoldTime', body.toJSON(), config);
  }
  async issueNexmoJwt(requestId: string | null) {
    const api = await this.apiWithAuth('');
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/nexmoJwt', config);
  }
  async joinNexmoConversation(
    requestId: string | null,
    orgUuid: UUID,
    conversationUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.JoinNexmoConversationResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.JoinNexmoConversationRequest.fromObject({
      conversationUuid,
    });
    return api.post('/fasttrack/v1/joinNexmoConversation', body, config);
  }
  async getMyLegInNexmoConversation(
    requestId: string | null,
    orgUuid: UUID,
    conversationUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.GetMyLegInNexmoConversationResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.GetMyLegInNexmoConversationRequest.fromObject({
      conversationUuid,
    });
    return api.post('/fasttrack/v1/getMyLegInNexmoConversation', body, config);
  }
  async hangup(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID
  ): Promise<AxiosResponse<infinitusai.be.HangupResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.HangupRequest.fromObject({
      taskUuid,
      callUuid,
    });
    return api.post('/fasttrack/v1/hangup', body, config);
  }
  async executeAction(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.FeExecuteActionRequest
  ): Promise<AxiosResponse<infinitusai.be.FeExecuteActionResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post(`/fasttrack/v1/executeAction`, body, config);
  }
  async updateCallRecordingEnabledSetting(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    recordingEnabled: boolean
  ): Promise<AxiosResponse<infinitusai.be.CallMonitorUpdateResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CallMonitorUpdateRequest.fromObject({
      taskUuid,
      callUuid,
      recordingEnabled: recordingEnabled
        ? infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.TRUE
        : infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.FALSE,
    });
    return api.post('/fasttrack/v1/updateCallMonitor', body, config);
  }
  async joinOrDepartCall(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.JoinOrDepartCallRequest
  ): Promise<AxiosResponse<infinitusai.be.JoinOrDepartCallResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/joinOrDepartCall', body.toJSON(), config);
  }

  //handler used  in FT to send a call back to IVR phase
  //this handler returns no reponse
  async reinstateHeadless(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.ReinstateHeadlessRequest
  ): Promise<null> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/reinstateHeadless', body.toJSON(), config);
  }

  async updateMuteStatus(
    requestId: string | null,
    orgUuid: UUID,
    body: infinitusai.be.UpdateMuteStatusRequest
  ) {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    return api.post('/fasttrack/v1/updateMuteEvent', body.toJSON(), config);
  }
  async updateCallAutoRespondSetting(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    autoRespond: boolean
  ): Promise<AxiosResponse<infinitusai.be.CallMonitorUpdateResponse>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.CallMonitorUpdateRequest.fromObject({
      taskUuid,
      callUuid,
      autoRespondIvrV2: autoRespond
        ? infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.TRUE
        : infinitusai.be.CallMonitorUpdateRequest.BOOL_OR_EMPTY.FALSE,
    });
    return api.post('/fasttrack/v1/updateCallMonitor', body, config);
  }
  async processCall(
    requestId: string | null,
    orgUuid: UUID,
    taskUuid: UUID,
    callUuid: UUID,
    outcome: keyof typeof infinitusai.be.FastTrackCallOutcome,
    details?: infinitusai.be.IFastTrackProcessCallDetails | null
  ): Promise<AxiosResponse<null>> {
    const api = await this.apiWithAuth(orgUuid);
    const config = this.apiWithConfig({ requestId });
    const body = infinitusai.be.ProcessFastTrackCallRequest.fromObject({
      taskUuid,
      callUuid,
      outcome,
      details,
    });
    return api.post('/fasttrack/v1/processCall', body.toJSON(), config);
  }
}

export { OperatorPortalApi, FastTrackApi };
