import { ApolloClient } from '@apollo/client';
import { AppName } from '@infinitusai/api';

import { ClientEvent } from '@infinitus/generated/frontend-common';
import {
  FeaturesMessage,
  LogEventMessage,
  LoggingTransport,
  WorkerFeatures,
  WorkerMessageType,
  WorkerResponse,
} from '@infinitus/types/service-worker';
import { CUSTOMER_PORTAL_URL, OPERATOR_PORTAL_URL } from '@infinitus/utils/constants';
import DebouncedBuffer from '@infinitus/utils/debouncedBuffer';
import { LogClientEventsDocument } from '@infinitus/utils/graphql';

const ALLOWED_ORIGINS = [OPERATOR_PORTAL_URL, CUSTOMER_PORTAL_URL];

class EventLoggingService {
  // Lazy initialized - will be created when first needed
  private apolloClient: ApolloClient<object> | null = null;

  // Default to OPERATOR
  private appName = AppName.OPERATOR;
  private buffer = new DebouncedBuffer<ClientEvent>({
    debounceTime: 5000,
    flushOnUnload: true,
    maxLength: 10,
    onFlush: (clientEvents) => this.logEvents(clientEvents),
  });
  private workerLoggingEnabled = false;
  private workerLoggingTransport?: LoggingTransport;

  constructor() {
    // Request features from worker on init and change
    void navigator?.serviceWorker?.ready.then(() => {
      this.listenForWorkerResponse();
      void this.checkWorkerFeatures();
    });
    navigator.serviceWorker?.addEventListener('controllerchange', (event) => {
      this.listenForWorkerResponse();
      void this.checkWorkerFeatures();
    });
  }

  log(event: ClientEvent) {
    if (this.shouldUseServiceWorker) {
      // TODO: We are deprecating logging via the service worker. we should
      // delete the code that logs using a service worker. this flow was
      // overkill for our needs and we should just log directly from the client.
      this.logViaServiceWorker(event);
    } else {
      this.bufferEvent(event);
    }
  }

  setAppName(appName: AppName) {
    this.appName = appName;
  }
  setApolloClient(apolloClient: ApolloClient<object>) {
    if (!this.apolloClient) {
      this.apolloClient = apolloClient;
    }
  }

  private bufferEvent(event: ClientEvent) {
    this.buffer.buffer([event]);
  }

  private async checkWorkerFeatures() {
    const message: FeaturesMessage = {
      type: WorkerMessageType.FEATURES,
    };
    navigator.serviceWorker?.controller?.postMessage(message);
  }

  private handleWorkerResponse(message: WorkerResponse) {
    switch (message.type) {
      case WorkerMessageType.FEATURES:
        this.workerLoggingEnabled = message.features.includes(WorkerFeatures.LOGGING);
        this.workerLoggingTransport = message.featuresConfig[WorkerFeatures.LOGGING]?.transport;
        console.info(
          `EventLoggingService: Service worker reported logging ${
            this.workerLoggingEnabled ? 'enabled' : 'disabled'
          } over transport ${this.workerLoggingTransport}`
        );
        break;
    }
  }

  private listenForWorkerResponse() {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (!ALLOWED_ORIGINS.includes(event.origin)) {
        console.warn('Received service worker message from unknown origin', event.origin);
        return;
      }
      const message = event.data as WorkerResponse;
      this.handleWorkerResponse(message);
    });
  }

  private async logEvents(clientEvents: ClientEvent[]) {
    if (process.env.NODE_ENV === 'test') {
      console.info('Skipping event logging during test');
      return;
    }
    if (!this.apolloClient) {
      return;
    }

    try {
      void this.apolloClient.mutate({
        mutation: LogClientEventsDocument,
        variables: {
          clientEvents,
        },
        context: {
          fetchOptions: {
            keepAlive: true,
          },
        },
      });
    } catch (error) {
      // TODO: Re-buffer events?
      console.error(`EventLoggingService: Failed to log client events: ${error}`, error);
    }
  }

  private logViaServiceWorker(event: ClientEvent) {
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (this.workerLoggingTransport) {
      case LoggingTransport.POST_MESSAGE:
        const message: LogEventMessage = {
          appName: this.appName,
          type: WorkerMessageType.LOG,
          // Some navigator info classes throw the "unclonable" error when attached to postMessage, so we convert to plain JSON objects manually
          event: JSON.parse(JSON.stringify(event)),
        };

        navigator.serviceWorker.controller?.postMessage(message);
        break;
    }
  }

  private get shouldUseServiceWorker(): boolean {
    return false;
  }
}

const EventLoggingServiceInstance = new EventLoggingService();
export default EventLoggingServiceInstance as EventLoggingService;
