import { ApolloLink, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { fromPromise } from '@apollo/client/link/utils';
import { getMainDefinition } from '@apollo/client/utilities';
import { AppName } from '@infinitusai/api';
import { getAuth } from 'firebase/auth';
import { createClient } from 'graphql-ws';

import { logGqlError } from '@infinitus/hooks/useLogBuffer';
import {
  BACKEND_PORT,
  BACKEND_SERVER_HOST,
  CURRENT_PROTOCOL,
  FRONTEND_VERSION,
  IS_SHARED_CODESPACE,
  WEBSOCKET_PROTOCOL,
} from '@infinitus/utils/constants';
import { startCorrelatingLogs } from '@infinitus/utils/logCorrelator';

import { isWSDebugEnabled } from './debugMode';
import {
  ORG_UUID_HEADER_FIELD,
  REQUEST_ID_HEADER_FIELD,
  FRONTEND_VERSION_HEADER_FIELD,
  FRONTEND_URL_HEADER_FIELD,
  APP_NAME_HEADER_FIELD,
} from './headers';

// Delay in ms before retrying a GQL operation after a failure
const ON_ERROR_RETRY_DELAY = 2000;

const getBackendServerURL = (protocol: string) => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV !== 'development') {
    return `${protocol}://${BACKEND_SERVER_HOST}`;
  } else if (IS_SHARED_CODESPACE) {
    return `${protocol}://${BACKEND_SERVER_HOST}/api`;
  }
  return `${protocol}://${BACKEND_SERVER_HOST}:${BACKEND_PORT}/api`;
};

const buildHeadersToInject = (authToken: string, appName: AppName, orgUuidOverride?: string) => ({
  // Need to pass the header for RestLink requests
  [ORG_UUID_HEADER_FIELD]:
    orgUuidOverride ?? (sessionStorage.getItem('orgUuid') || localStorage.getItem('orgUuid') || ''),
  // Note: REQUEST_ID_HEADER_FIELD is omitted here because it is set in the correlatedLogsLink
  [FRONTEND_VERSION_HEADER_FIELD]: FRONTEND_VERSION,
  [FRONTEND_URL_HEADER_FIELD]: window.location.href,
  [APP_NAME_HEADER_FIELD]: appName,
  Authorization: authToken,
});

const getAuthToken = async (): Promise<string> => {
  const currentUser = getAuth().currentUser;
  if (!currentUser) {
    return new Promise<string>((resolve, reject) => {
      getAuth().onAuthStateChanged(async (user) => {
        if (user) {
          resolve(await user.getIdToken());
        } else {
          resolve('');
        }
      });
    });
  } else {
    return currentUser?.getIdToken();
  }
  // return 'something';
};

const buildAuthLink = (appName: AppName) => {
  const authLink = setContext((req, { headers }) => {
    const orgUuidOverride = req.variables
      ? req.variables['orgUuid'] ?? req.variables['orgUUID']
      : undefined;
    return new Promise(async (success) => {
      try {
        const token = await getAuthToken();
        success({
          headers: {
            // Note: headers is first created by correlatedLogsLink
            ...headers,
            ...buildHeadersToInject(token ?? '', appName, orgUuidOverride),
          },
        });
      } catch (e) {
        console.error('buildAuthLink', e);
        success({ headers });
      }
    });
  });
  return authLink;
};

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const { operationName, variables } = operation;
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      const errMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      logGqlError(errMessage, {
        operationName,
        variables,
      });
    });
  if (networkError) {
    const errMessage = `[Network error]: ${networkError}`;
    logGqlError(errMessage, {
      operationName,
      variables,
    });
  }
  // Retry once first (ref: https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors)
  // Retry the request after a short delay, returning the new observable
  const promise = fromPromise(
    new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, ON_ERROR_RETRY_DELAY);
    })
  );
  return promise.flatMap(() => forward(operation));
});

const httpLink = createHttpLink({
  uri: (operation) => {
    const { operationName } = operation;

    let url = `${getBackendServerURL(CURRENT_PROTOCOL)}/graphql`;
    if (operationName) {
      url += `?operationName=${operationName}`;
    }
    return url;
  },
});

const buildWsLink = (appName: AppName) => {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${getBackendServerURL(WEBSOCKET_PROTOCOL)}/graphql-ws`,
      connectionParams: async () => {
        const token = await getAuthToken();
        return {
          ...buildHeadersToInject(token ?? '', appName),
        };
      },
      // Always attempt to reconnect incase of intermittent network issues, tab wake up after sleep, etc.
      retryAttempts: Infinity,
      //  Always attempt to reconnect, by default the client will immediately fail on any non-fatal
      // `CloseEvent` problem thrown during the connection phase.
      shouldRetry: () => true,
      // Send/receive ping/pong messages to keep the connection alive
      keepAlive: 10_000,
      // event listeners
      on: {
        connecting: () => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] connecting');
          }
        },
        opened: (socket) => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] opened', { socket });
          }
        },
        connected: (socket, payload) => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] connected', { socket, payload });
          }
        },
        ping: (received, payload) => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] ping', { received, payload });
          }
        },
        pong: (received, payload) => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] pong', { received, payload });
          }
        },
        message: (message) => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] message', { message });
          }
        },
        closed: (event) => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] closed', { event });
          }
        },
        error: (error) => {
          if (isWSDebugEnabled()) {
            console.info('[Graphql WebSocket] error', { error });
          }
        },
      },
    })
  );
  return wsLink;
};

export const correlatedLogsLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  const prevContext = operation.getContext();
  const { headers } = prevContext;
  const requestIdFromContext = headers ? headers[REQUEST_ID_HEADER_FIELD] : undefined;
  const { requestId, correlatedLog } = startCorrelatingLogs({ requestId: requestIdFromContext });

  if (!requestIdFromContext) {
    if (!prevContext.headers) {
      prevContext.headers = {};
    }
    prevContext.headers[REQUEST_ID_HEADER_FIELD] = requestId;
    operation.setContext(prevContext);
  }

  const t0 = performance.now();
  correlatedLog(`Request operation ${operation.operationName}`);

  return forward(operation).map((data) => {
    // Called after server responds
    const t1 = performance.now();
    correlatedLog(
      `Received data for operation ${operation.operationName} after ${(
        t1 - t0
      ).toFixed()} milliseconds`
    );
    return data;
  });
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 20000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

export const getHttpTransportLinkChain = (appName: AppName) => [
  retryLink,
  correlatedLogsLink,
  errorLink,
  buildAuthLink(appName),
];
export const buildTransportSplitLink = (appName: AppName) => {
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    buildWsLink(appName),
    ApolloLink.from([...getHttpTransportLinkChain(appName), httpLink])
  );
  return splitLink;
};
