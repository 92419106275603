import { colors, lighten } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { blueGrey, grey, teal, yellow } from '@mui/material/colors';
import { alpha, darken } from '@mui/material/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

declare module '@mui/material/styles' {
  interface TypeBackground {
    accented?: string;
    accentedTheme?: string;
    dataSourceAdornment?: string;
    v4Paper?: string;
  }

  interface Palette {
    chatBubbles: {
      autoResponse: string;
      focusedOutline: string;
      incident: string;
      incidentOutline: string;
      recipient: string;
      silence: string;
      suggestion: string;
      user: string;
    };
    payerIntelligenceRules?: {
      activeItem: string;
      conflictedItemEscalate: string;
      conflictedItemPushback: string;
      satisfiedItem: string;
    };
    popperCollars: {
      completed: string;
      default: string;
      erroneous: string;
      piActive: string;
      questionable: string;
      skipped: string;
    };
    selectedTab: {
      backgroundColor: string;
    };
    wavesurfer: {
      cursor: string;
      playhead: string;
      progress: string;
    };
  }
  interface PaletteOptions {
    chatBubbles?: {
      autoResponse?: string;
      focusedOutline?: string;
      incident?: string;
      incidentOutline?: string;
      recipient?: string;
      silence?: string;
      suggestion?: string;
      user?: string;
    };
    payerIntelligenceRules?: {
      activeItem: string;
      conflictedItemEscalate: string;
      conflictedItemPushback: string;
      satisfiedItem: string;
    };
    popperCollars?: {
      completed?: string;
      default?: string;
      erroneous?: string;
      piActive?: string;
      questionable?: string;
      skipped?: string;
    };
    selectedTab?: {
      backgroundColor?: string;
    };
    wavesurfer: {
      cursor: string;
      playhead: string;
      progress: string;
    };
  }
}

// Adds v4h5 variant to Typescript
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    v4h5: true;
  }
}

// Set constants here
let RootTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#408CC2',
    },
    secondary: {
      main: '#C37342',
    },
    text: {
      primary: colors.common.white,
    },
    background: {
      default: 'rgb(48, 48, 48)',
      v4Paper: '#424242',
      accented: colors.grey[700],
      accentedTheme: darken('#408CC2', 0.15),
      dataSourceAdornment: lighten('#F5F5F5', 0.2),
    },
    wavesurfer: {
      playhead: '#FFA629',
      progress: '#91643D',
      cursor: '#DFBA49',
    },
    payerIntelligenceRules: {
      activeItem: darken(blueGrey[300], 0.3),
      conflictedItemPushback: darken('#FFA000', 0.25),
      conflictedItemEscalate: darken('#D32F2F', 0.5),
      satisfiedItem: darken(teal[500], 0.25),
    },
  },
  transitions: {
    duration: {
      shortest: 100,
      shorter: 150,
      short: 200,
      standard: 250,
      complex: 350,
      enteringScreen: 300,
      leavingScreen: 250,
    },
    // Standard Material
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {},
    h5: {
      textTransform: 'none',
      // TODO: Replace current usage of h5 with h3
      // Below are the v4 styles for h5. We seem to be incorrectly using
      // Mui5 h5 when we should be using h3.
      // In the meantime, we can use the custom "v4h5" variant defined near
      // the bottom of this file.
      // textTransform: 'uppercase',
      // fontSize: '1rem',
      // lineHeight: '1.6rem',
      // fontWeight: 500,
    },
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontSize: '1rem',
    },
    body2: {},
    button: {
      textTransform: 'none',
    },
    caption: {
      fontSize: '.8rem',
    },
    overline: {
      textTransform: 'none',
    },
  },
});

export const THEME_COLORS = {
  primary: '#408CC2',
  secondary: '#C37342',
  brand: '#408CC2',
  tooltipBackground: alpha(RootTheme.palette.common.black, 0.9),
  accentedBackground: darken(grey[700], 0.25),
};
const PAYER_INTELLIGENCE_COLORS = {
  active: blueGrey[300],
  pushback: RootTheme.palette.warning.dark,
  escalate: RootTheme.palette.error.dark,
  satisfied: teal[500],
};
const DEFAULT_POPPER_COLLAR_BG_COLOR = alpha(THEME_COLORS.primary, 0.3);

RootTheme = createTheme({
  palette: {
    ...RootTheme.palette,
    chatBubbles: {
      autoResponse: RootTheme.palette.primary.main,
      user: blueGrey[700],
      recipient: grey[700],
      silence: '#303030',
      suggestion: THEME_COLORS.accentedBackground,
      incident: '#352626', // alpha('#EB5757', 0.1),
      incidentOutline: '#EB5757',
      focusedOutline: 'white',
    },
    popperCollars: {
      default: DEFAULT_POPPER_COLLAR_BG_COLOR,
      completed: alpha(PAYER_INTELLIGENCE_COLORS.satisfied, 0.3),
      skipped: alpha(yellow[200], 0.3),
      questionable: alpha(PAYER_INTELLIGENCE_COLORS.pushback, 0.3),
      erroneous: alpha(PAYER_INTELLIGENCE_COLORS.escalate, 0.3),
      piActive: DEFAULT_POPPER_COLLAR_BG_COLOR,
    },
    selectedTab: {
      backgroundColor: '#616161',
      // TODO(yoshi) Use '#222D34' background color when the background color changes in EQ-311
    },
    payerIntelligenceRules: {
      activeItem: darken(PAYER_INTELLIGENCE_COLORS.active, 0.3),
      conflictedItemPushback: darken(PAYER_INTELLIGENCE_COLORS.pushback, 0.25),
      conflictedItemEscalate: darken(PAYER_INTELLIGENCE_COLORS.escalate, 0.5),
      satisfiedItem: darken(PAYER_INTELLIGENCE_COLORS.satisfied, 0.25),
    },
  },
});

const dataTableHideCloseButtonStyles = {
  display: 'none',
  pointerEvents: 'none',
  userSelect: 'none',
};
const dataTablePopoverTitleStyles = {
  fontSize: RootTheme.typography.h6.fontSize,
};

// Component overrides go here
// Reference default theme where possible
const MuiTheme = createTheme(
  deepmerge(RootTheme, {
    components: {
      MuiAccordion: {
        defaultProps: {
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            backgroundColor: RootTheme.palette.background.v4Paper,
            '&:focus': {
              boxShadow: `inset 0 0 2px 2px ${RootTheme.palette.primary.main}85`,
            },
            '&.Mui-expanded:before': {
              opacity: 1, // Show border on expanded accordion
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            bgcolor: 'red',
            padding: `0 ${RootTheme.spacing(2)}px`,
            '&$expanded': {
              minHeight: 20,
              margin: 0,
            },
            '&:hover': {
              borderRadius: RootTheme.shape.borderRadius,
              bgcolor: lighten(RootTheme.palette.background.default, 0.12),
            },
            '&:active': {
              borderRadius: RootTheme.shape.borderRadius,
              bgcolor: lighten(RootTheme.palette.background.default, 0.1),
            },
            '&:focus': {
              borderRadius: RootTheme.shape.borderRadius,
              bgcolor: lighten(RootTheme.palette.background.default, 0.08),
              boxShadow: `inset 0 0 2px 2px ${RootTheme.palette.primary.main}85`,
            },
          },
          content: {
            flexShrink: 1,
            margin: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            // Hack to enable overflowing ellipsis summary text
            width: 0,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          endAdornment: {
            insetBlockStart: RootTheme.spacing(1),
            insetInlineEnd: RootTheme.spacing(1),
          },
          inputRoot: {
            paddingRight: RootTheme.spacing(2),
            '& .MuiAutocomplete-input': {
              // unset the width: 0 defined in the autocomplete component:
              // https://github.com/mui/material-ui/blob/3e9b63164b5df370393777a1263258e409490227/packages/mui-material/src/Autocomplete/Autocomplete.js#L111
              width: 'unset',
            },
          },
          root: {
            zIndex: 1,
            '& .MuiChip-root': {
              marginBlockStart: RootTheme.spacing(1),
            },
            '& .MuiInputBase-inputSizeSmall:not(.MuiFilledInput-input)': {
              paddingBlock: RootTheme.spacing(1),
            },
            '& .MuiInputBase-root': {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              columnGap: RootTheme.spacing(0.5),
            },
          },
          groupLabel: {
            lineHeight: '1.1rem',
            paddingBlock: RootTheme.spacing(1),
            paddingInline: RootTheme.spacing(1),
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            marginInline: RootTheme.spacing(0.25),
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            flexShrink: 0,
          },
          label: {
            whiteSpace: 'nowrap',
          },
        },
      },
      MuiCardContent: {
        root: {
          paddingTop: '0.5rem',
        },
      },
      MuiChip: {
        styleOverrides: {
          label: {
            lineHeight: 1,
            // Don't crop descenders (g, p, y, etc)
            overflow: 'visible',
          },
          root: {
            '& .MuiChip-deleteIcon': {
              cursor: 'pointer',
              marginLeft: RootTheme.spacing(-0.5),
            },
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          disableGutters: true,
          size: 'xl',
        },
      },
      MuiGrid: {
        defaultProps: {
          columnSpacing: 2,
          rowSpacing: 3,
        },
      },
      MuiIconButton: {
        styleOverrides: {
          // This brutal CSS hack is to remove the close button from the show columns popover in the MUIDataTable
          root: {
            '&:has(~ fieldset[class*="MUIDataTableViewCol-root"])': dataTableHideCloseButtonStyles,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              '&::before': {
                borderBottomStyle: 'solid',
                opacity: 0.5,
              },
            },
          },
          input: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            display: 'block',
            width: '100%',
          },
        },
      },
      MUIDataTableFilter: {
        styleOverrides: {
          resetLink: {
            background: 'transparent',
            fontSize: 14,
            marginLeft: 0,
          },
          reset: {
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            width: '100%',
          },
          root: {
            padding: RootTheme.spacing(2),
            width: 560,
          },
          title: {
            ...dataTablePopoverTitleStyles,
            marginLeft: 0,
          },
        },
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          chip: {
            margin: 0,
            marginBlockEnd: RootTheme.spacing(1),
            marginInlineEnd: RootTheme.spacing(0.5),
            paddingInlineEnd: RootTheme.spacing(0.5),
            '& .MuiSvgIcon-root': {
              cursor: 'pointer',
            },
          },
          root: {
            margin: 0,
            paddingInline: RootTheme.spacing(2),
          },
        },
      },
      MUIDataTableHeader: {},
      MUIDataTableHeadCell: {
        styleOverrides: {
          toolButton: {
            backgroundColor: RootTheme.palette.grey[700],
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: RootTheme.spacing(-1),
          },
          filterPaper: {
            // Remove "X" (close) button, keep "Reset" button
            // No UX issue because they have a clickable backdrop to escape
            // making the button unnecessary
            '& > .MuiIconButton-root': dataTableHideCloseButtonStyles,
          },
          left: {
            alignItems: 'center',
            display: 'flex',
          },
          root: {
            minHeight: RootTheme.spacing(7),
            paddingLeft: RootTheme.spacing(2),
            paddingRight: RootTheme.spacing(2),
            paddingTop: RootTheme.spacing(1),
            paddingBottom: RootTheme.spacing(1),
            flexFlow: 'row wrap',
          },
        },
      },
      MUIDataTableViewCol: {
        // Close button is removed via hacky selector on MuiIconButon styleOverrides
        styleOverrides: {
          root: {
            // Used to match the MuiDataTableToolbar-filterPaper
            backgroundColor: RootTheme.palette.background.paper,
            minWidth: 240,
          },
          title: dataTablePopoverTitleStyles,
        },
      },
      MUIDataTableSearch: {
        clearIcon: {
          display: 'none',
        },
      },
      MuiDialogTitle: {
        root: {
          textTransform: 'uppercase',
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: RootTheme.palette.text.secondary,
          },
        },
        focused: {},
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiPopover: {
        root: {
          zIndex: RootTheme.zIndex.tooltip + 1,
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
          outlined: {
            padding: '8px 12px',
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
          variant: 'rounded',
        },
      },
      MuiSnackBar: {
        defaultProps: {
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
          autoHideDuration: 3000,
        },
        styleOverrides: {
          root: {
            color: RootTheme.palette.common.white,
            [RootTheme.breakpoints.up('sm')]: {
              minWidth: 0,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              cursor: 'default',
              pointerEvents: 'auto',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          scrollableX: {
            '&::-webkit-scrollbar': {
              height: 8,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          maxRows: 3,
          size: 'small',
        },
      },
      MuiTooltip: {
        defaultProps: {
          leaveDelay: 100,
          TransitionComponent: Zoom,
        },
        styleOverrides: {
          tooltip: {
            backgroundColor: RootTheme.palette.common.black,
            position: 'relative',
            fontSize: '0.9em',
          },
          arrow: {
            '&::before': {
              bgcolor: THEME_COLORS.tooltipBackground,
            },
          },
          tooltipPlacementLeft: {
            margin: `0 ${RootTheme.spacing(1)}px`,
          },
          tooltipPlacementRight: {
            margin: `0 ${RootTheme.spacing(1)}px`,
          },
          tooltipPlacementTop: {
            margin: `0 ${RootTheme.spacing(1)}px`,
          },
          tooltipPlacementBottom: {
            margin: `0 ${RootTheme.spacing(1)}px`,
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          // Avoid switching to text cursor because this isn't a media app.
          // UI elements use `text` or `pointer` cursor.
          root: {
            cursor: 'inherit',
            '&.Mui-disabled': {
              color: RootTheme.palette.text.disabled,
            },
          },
          h1: { cursor: 'default' },
          h2: { cursor: 'default' },
          h3: { cursor: 'default' },
          h4: { cursor: 'default' },
          h5: { cursor: 'default' },
          h6: { cursor: 'default' },
        },
        variants: [
          {
            props: { variant: 'v4h5' },
            style: {
              textTransform: 'uppercase',
              fontSize: '1rem',
              lineHeight: '1.6rem',
              fontWeight: 500,
              display: 'block', // Added to match browser default for h5
            },
          },
        ],
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      caption: {
        fontWeight: 300,
        letterSpacing: -0.25,
      },
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontSize: '2rem',
        fontWeight: 500,
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: 500,
      },
      h4: {},
      h5: {},
      h6: {},
    },
  })
);

export default responsiveFontSizes(MuiTheme);
