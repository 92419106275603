/**
 * This class serves as an adapter between our React app and the Nexmo JS client.
 * It handles authentication and manages the media stream (e.g. to ensure we only have a single
 * connection at any one time).
 */

import '@infinitus/nexmo/nexmo-client.d';
import { AppName } from '@infinitusai/api';
import { EventEmitter } from 'events';
import NexmoClient, { Application, Conversation, Member, NXMEvent } from 'nexmo-client';

import { ClientEventType } from '@infinitus/generated/gql/graphql';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import { UUID } from '@infinitus/types';
import { FastTrackApi, OperatorPortalApi } from '@infinitus/utils/api';
import { PerformanceMarks, PerformanceMeasures } from '@infinitus/utils/constants';
import { startCorrelatingLogs } from '@infinitus/utils/logCorrelator';
import { wrapPromiseWithTimeout } from '@infinitus/utils/promiseHelpers';

import { NexmoAttachRequestSource, NexmoClientAttachProps } from './NexmoContext';

export enum Events {
  EARMUFF_STATE_CHANGE = 'EARMUFF_STATE_CHANGE',
  MUTE_STATE_CHANGE = 'MUTE_STATE_CHANGE',
  CHANGING_MUTE_STATE = 'CHANGING_MUTE_STATE',
  CHANGED_MUTE_STATE = 'CHANGED_MUTE_STATE',
  DISCONNECT_DETECTED = 'DISCONNECT_DETECTED',
  LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED',
  LOGIN_FAILED = 'LOGIN_FAILED',
  JOINING_CONVERSATION = 'JOINING_CONVERSATION',
  JOINED_CONVERSATION = 'JOINED_CONVERSATION',
  ATTEMPTING_AUTO_RECONNECT = 'ATTEMPTING_AUTO_RECONNECT',
  LEFT_CONVERSATION = 'LEFT_CONVERSATION',
}

export enum MuteState {
  MUTED = 'muted',
  UNMUTED = 'unmuted',
  UNKNOWN = 'unknown',
}

export enum EarMuffState {
  EARMUFFED = 'earmuffed',
  UNEARMUFFED = 'unearmuffed',
  UNKNOWN = 'unknown',
}

interface LogEvent {
  clientEventType: ClientEventType;
  message: string;
  meta?: any;
}

export type LogEventFunction = (event: LogEvent) => Promise<void>;

export interface NexmoClientServiceOptions {
  appName: AppName;
  deviceId: string;
  disablePolling: boolean;
  logEvent: LogEventFunction;
}

export const defaultLogEventHandler = async (event: LogEvent) => {
  console.warn(
    '[Nexmo] No LogEventHandler passed to the NexmoClientService. Event emitted: ',
    JSON.stringify(event, null, 2)
  );
};

export const defaultNexmoClientServiceOptions: NexmoClientServiceOptions = {
  appName: AppName.OPERATOR,
  disablePolling: false,
  logEvent: defaultLogEventHandler,
  deviceId: '',
};

// ref: https://developer.nexmo.com/sdk/stitch/javascript/sdk.js.html#line90
const NEXMO_CLIENT_CONFIG = {
  sync: 'none',
};

// Nexmo failed API calls recoveries
export const NEXMO_LOGIN_TIMEOUT = 2000;
export const NEXMO_LOGIN_RETRIES = 4;
// Nexmo JWT refresh interval in ms
export const REFRESH_NEXMO_JWT_POLL_INTERVAL = 1000 * 60 * 60 * 2;
// Time in ms between attempting to sign into Nexmo
export const NEXMO_LOGIN_TIME_BETWEEN_RETRIES = 2000;
export const NEXMO_EXPIRED_TOKEN_RETRIES = 3;
// Polling interval to check mute state in ms
export const NEXMO_MUTE_CHECK_POLLING_INTERVAL = 1000;

export type NexmoClientFactory = () => NexmoClient;

// Util to await a specific duration before resolving a promise
export const asyncWaitForDuration = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

class NexmoClientService extends EventEmitter {
  private _nexmoClientFactory: NexmoClientFactory;
  private _nexmoClientInstance: NexmoClient | null = null;
  private _options: NexmoClientServiceOptions;
  private _jwt: string = '';
  private _conversation: Conversation | undefined;
  private _isLoggingIn = false;
  // Stores the promise for a login attempt
  // This allows us to await any login in progress (e.g. during an attach() call)
  private _loginPromise: Promise<void> = Promise.resolve();
  private _isLoggedIn = false;
  private _isAttaching = false;
  private _isGettingConversation = false;
  private _pollMuteStateTimerId: NodeJS.Timeout | undefined = undefined;
  private _previousMuteState = MuteState.UNKNOWN;
  private _previousEarmuffState = EarMuffState.UNKNOWN;
  private _callShouldBeMuted = true;
  private _enablePolling = false;
  private _pollNewNexmoJwtTimerId: NodeJS.Timeout | undefined = undefined;
  private _enableJwtPolling = false;
  private _orgUuid: string = '';
  private _api: OperatorPortalApi | FastTrackApi;
  private _muteCheckInterval: number = NEXMO_MUTE_CHECK_POLLING_INTERVAL;

  constructor(
    nexmoClientFactory: NexmoClientFactory = () => new NexmoClient(NEXMO_CLIENT_CONFIG),
    serviceOptions?: Partial<NexmoClientServiceOptions>
  ) {
    super();

    const serviceOptionsWithDefaults: NexmoClientServiceOptions = {
      ...defaultNexmoClientServiceOptions,
      ...serviceOptions,
    };

    switch (serviceOptionsWithDefaults.appName) {
      case AppName.OPERATOR:
        this._api = new OperatorPortalApi();
        break;
      case AppName.FASTTRACK:
        this._api = new FastTrackApi();
        break;
      default:
        throw new Error('Invalid app name provided to NexmoClientService');
    }

    this._nexmoClientFactory = nexmoClientFactory;
    this._options = serviceOptionsWithDefaults;
    this.attach = this.attach.bind(this);
    this.maybeLeaveConversation = this.maybeLeaveConversation.bind(this);
    this.handleRtcAnswered = this.handleRtcAnswered.bind(this);
    this.handleRtcHangup = this.handleRtcHangup.bind(this);
    this.setEarmuffed = this.setEarmuffed.bind(this);
    this.setMuted = this.setMuted.bind(this);
    this.updateAudioConstraints = this.updateAudioConstraints.bind(this);
    this.handleEarMuffOffEvent = this.handleEarMuffOffEvent.bind(this);
    this.handleEarMuffOnEvent = this.handleEarMuffOnEvent.bind(this);
    this.handleMuteOnEvent = this.handleMuteOnEvent.bind(this);
    this.handleMuteOffEvent = this.handleMuteOffEvent.bind(this);
    this.startNewSession = this.startNewSession.bind(this);
  }

  async initialize() {
    await this.login();
  }

  get client() {
    return this._nexmoClientInstance;
  }

  isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  private async fetchNexmoJWT(): Promise<void> {
    const { requestId, correlatedLog, correlatedError } = startCorrelatingLogs();
    try {
      let msg = '[Nexmo] Fetching Nexmo JWT...';
      correlatedLog(msg);
      console.log(msg);
      const jwtResponse = await this._api.issueNexmoJwt(requestId);
      if (!jwtResponse.data.nexmoJwt) {
        throw new Error(
          `Didn't receive a Nexmo JWT from the backend. Response was ${JSON.stringify(jwtResponse)}`
        );
      }
      this._jwt = jwtResponse.data.nexmoJwt;
      msg = `[Nexmo] Received Nexmo JWT ending in ${this._jwt.slice(-6)}`;
      correlatedLog(msg);
      console.log(msg);
    } catch (e: any) {
      const msg = `[Nexmo] Failed to retrieve Nexmo JWT: ${e}`;
      correlatedError(msg);
      throw e;
    }
  }

  private async refreshNexmoJWT() {
    if (!this.isLoggedIn()) return;
    if (!this._nexmoClientInstance) return;

    try {
      console.log('[Nexmo] Refreshing Nexmo JWT...');
      await this.fetchNexmoJWT();
      await this._nexmoClientInstance.application.updateToken(this._jwt);
    } catch (e: any) {
      console.error('[Nexmo] Failed to refresh Nexmo JWT', JSON.stringify(e));
    }
  }

  // We're using a wrapper here to capture the promise for login attempts in progress
  login(): Promise<void> {
    if (this._isLoggingIn) {
      return this._loginPromise;
    }
    this._loginPromise = this._login();
    return this._loginPromise;
  }

  private async _login(): Promise<void> {
    if (this.isLoggedIn()) return;
    if (this._isLoggingIn) return this._loginPromise;
    this._nexmoClientInstance = this._nexmoClientFactory();
    this._isLoggingIn = true;
    console.log('[Nexmo] Logging into NexmoClientService...');

    // Allow multiple attempts to sign into Nexmo with timeouts
    let nexmoLoginRetriesLeft = NEXMO_LOGIN_RETRIES;
    const signIntoNexmo = async () => {
      console.log(
        `[Nexmo] Attempting to login into Nexmo client (${nexmoLoginRetriesLeft} attempts left)...`
      );
      nexmoLoginRetriesLeft--;
      try {
        await wrapPromiseWithTimeout<Application>(
          this._nexmoClientInstance!.createSession(this._jwt),
          NEXMO_LOGIN_TIMEOUT,
          {
            error: () => new Error('Timed out when trying to sign into Nexmo'),
            onTimeout: () => {
              console.warn('[Nexmo] Timeout when trying to sign into Nexmo');
            },
          }
        );
        console.log('[Nexmo] Successfully signed into the NexmoClientService.');
        this._isLoggedIn = true;
        this.emit(Events.LOGIN_SUCCEEDED);
        this.startPollingNewNexmoJWT();
        this._nexmoClientInstance?.application.on(
          'system:error:expired-token',
          'NXM-errors',
          async (error: any) => {
            console.log(`[Nexmo] Nexmo JWT Token Expired: ${error}`);
            try {
              await this.fetchNexmoJWT();
              void this._nexmoClientInstance?.application.updateToken(this._jwt);
              console.log('[Nexmo] JWT successfully refreshed');
            } catch (e: any) {
              console.error(`[Nexmo] Failed to refresh Nexmo JWT Token: ${e.message || e}
                  ${e.stack}`);
            }
          }
        );
      } catch (e: any) {
        console.error(`[Nexmo] Failed Nexmo sign-in attempt: ${e.message}
            ${e.stack}`);
        if (nexmoLoginRetriesLeft <= 0) {
          this.emit(Events.LOGIN_FAILED);
          const message = `Failed signing into Nexmo after ${NEXMO_LOGIN_RETRIES} attempts.`;
          void this._options.logEvent({
            message,
            clientEventType: ClientEventType.NEXMO,
            meta: {
              conversationId: this._conversation?.id,
              error: 'failed-sign-in',
            },
          });
          throw new Error(message);
        }
        await asyncWaitForDuration(NEXMO_LOGIN_TIME_BETWEEN_RETRIES);
        await signIntoNexmo();
      } finally {
        this._isLoggingIn = false;
      }
    };

    try {
      await this.fetchNexmoJWT();
      console.log('[Nexmo] JWT successfully retrieved, now logging into Nexmo client...');
      await signIntoNexmo();
    } catch (e: any) {
      console.error(`[Nexmo] Failed to sign into Nexmo: ${e.message}
          ${e.stack}`);
      this._isLoggingIn = false;
      this._isLoggedIn = false;
      throw e;
    }
  }

  async logout(): Promise<void> {
    if (!this._isLoggedIn || !this._nexmoClientInstance) return;
    console.log('[Nexmo] Logging out of NexmoClientService...');
    try {
      await this._nexmoClientInstance.deleteSession();
      this._jwt = '';
      this._isLoggedIn = false;
      this._conversation = undefined;
      this.stopPollingNewNexmoJWT();
      console.log('[Nexmo] Logged out.');
    } catch (e: any) {
      console.error(`[Nexmo] Failed to logout of Nexmo: ${e.message}`);
      throw e;
    }
  }

  /*
      It's important that we maintain muting, and we have had trouble in the past with calls unmuting themselves,
      so we'll continue polling and checking the mute state throughout the life of the call.
      There are 2 ways that we can detect whether a call is muted:
       1) By listening for the audio:mute:(on|off) events.
       2) By refreshing the Conversation object from Nexmo and inspecting the audio_settings.
          This was our advised approach from Nexmo support (ticket 1563124).
       We use #1 to simply log to the console, and we use #2 to poll for the latest mute state.
    */
  private startPollingMuteState() {
    console.log(
      `[Nexmo] Starting polling Nexmo for mute state every ${this._muteCheckInterval}ms.`
    );

    this._enablePolling = true;

    const checkMuteState = async () => {
      if (this._pollMuteStateTimerId) clearTimeout(this._pollMuteStateTimerId);
      if (!this._enablePolling) return;
      try {
        await this.refreshConversation();
      } catch (e: any) {
        console.error(`[Nexmo] Failed to refresh Nexmo conversation: ${e.message}`);
        return;
      }
      if (!this._conversation?.me.id) {
        throw new Error(`Unable to determine current user ID.`);
      }
      const me = await this._conversation.getMember(this._conversation.me.id);
      if (!me.media) {
        throw new Error("Unable to get current user's media object.");
      }
      const {
        audio_settings: { muted, earmuffed },
      } = me.media;
      if (this._callShouldBeMuted !== muted) {
        console.warn(
          `[Nexmo] Call should ${
            this._callShouldBeMuted ? '' : 'not '
          }be muted, but Nexmo audio_settings.muted is ${muted}.`
        );
        void this.setMuted(this._callShouldBeMuted);
      }

      const newMuteState =
        muted === true ? MuteState.MUTED : muted === false ? MuteState.UNMUTED : MuteState.UNKNOWN;

      const newEarMuffState =
        earmuffed === true
          ? EarMuffState.EARMUFFED
          : earmuffed === false
          ? EarMuffState.UNEARMUFFED
          : EarMuffState.UNKNOWN;

      if (newMuteState !== this._previousMuteState) {
        console.log(
          `[Nexmo] Nexmo mute state changed from '${this._previousMuteState}' to '${newMuteState}'`
        );
        this.emit(Events.MUTE_STATE_CHANGE, newMuteState);
      }
      if (newEarMuffState !== this._previousEarmuffState) {
        console.log(
          `[Nexmo] Nexmo earmuff state changed from '${this._previousEarmuffState}' to '${newEarMuffState}'`
        );
        this.emit(Events.EARMUFF_STATE_CHANGE, newEarMuffState);
      }

      this._previousMuteState = newMuteState;
      this._previousEarmuffState = newEarMuffState;

      this._pollMuteStateTimerId = setTimeout(checkMuteState, this._muteCheckInterval);
    };

    void checkMuteState();
  }

  private stopPollingMuteState() {
    console.log('[Nexmo] Stopping polling Nexmo for mute state.');
    this._enablePolling = false;
    if (this._pollMuteStateTimerId) clearTimeout(this._pollMuteStateTimerId);
  }

  /*
    We want to maintain auth to avoid the time needed to re-login
    to nexmo when starting a new session
  */
  private startPollingNewNexmoJWT() {
    console.log(
      `[Nexmo] Starting polling new Nexmo jwt every ${REFRESH_NEXMO_JWT_POLL_INTERVAL}ms.`
    );
    this._enableJwtPolling = true;

    const maybeRefreshNexmoJWT = async () => {
      if (this._pollNewNexmoJwtTimerId) clearTimeout(this._pollNewNexmoJwtTimerId);
      if (!this._enableJwtPolling) return;
      if (!this.isLoggedIn()) return;
      if (!this._nexmoClientInstance) return;

      void this.refreshNexmoJWT();

      this._pollNewNexmoJwtTimerId = setTimeout(
        maybeRefreshNexmoJWT,
        REFRESH_NEXMO_JWT_POLL_INTERVAL
      );
    };

    // Schedule the first invocation to happen in the future so that we don't
    // immediately refresh the JWT when the user logs in.
    this._pollNewNexmoJwtTimerId = setTimeout(
      maybeRefreshNexmoJWT,
      REFRESH_NEXMO_JWT_POLL_INTERVAL
    );
  }

  private stopPollingNewNexmoJWT() {
    console.log('[Nexmo] Stopping polling new Nexmo JWT.');
    this._enableJwtPolling = false;

    if (this._pollNewNexmoJwtTimerId) clearTimeout(this._pollNewNexmoJwtTimerId);
  }

  private async getConversation(orgUuid: UUID, id: string): Promise<Conversation | undefined> {
    if (this._conversation && this._conversation.id === id) return this._conversation;
    if (this._isGettingConversation) return;
    if (!this._nexmoClientInstance)
      throw new Error('Expected NexmoClientInstance to have been constructed');
    if (!this._nexmoClientInstance.application) {
      console.warn('[Nexmo] Nexmo application not yet available');
      return;
    }

    // Build a re-usable function to support retries (e.g. if the auth token has expired)
    let nexmoGetConversationRetriesLeft = NEXMO_EXPIRED_TOKEN_RETRIES;
    const attemptToRetrieveConversation = async (): Promise<Conversation | undefined> => {
      nexmoGetConversationRetriesLeft--;
      console.log(
        `[Nexmo] Attempting to retrieve conversation ${id} (${nexmoGetConversationRetriesLeft} attempts left)...`
      );
      this._isGettingConversation = true;
      try {
        const conversation = await this._nexmoClientInstance!.application.getConversation(id);
        const shouldJoinConversation = !conversation.me || conversation.me.state !== 'JOINED';
        if (shouldJoinConversation) {
          console.log(`[Nexmo] Joining Nexmo conversation ${id}...`);
          try {
            const member = await conversation.join();
            console.log(`[Nexmo] Joined conversation as member ${member.id}.`);
          } catch (e: any) {
            // If we rejoin a conversation without leaving, Nexmo will throw a member-already-joined
            if (e.type === 'conversation:error:member-already-joined') {
              console.warn(
                `[Nexmo] Conversation ${id} threw a conversation:error:member-already-joined error, will leave and re-join...`
              );
              await conversation.leave();
              const member = await conversation.join();
              console.log(`[Nexmo] Re-joined conversation as member ${member.id}.`);
            } else {
              throw e;
            }
          }
        } else {
          console.log(
            `[Nexmo] Not joining conversation, we are already joined as member ${conversation.me.id} in state ${conversation.me.state}.`
          );
        }
        const members = await conversation.getMembers();
        if (!!members.items.size) {
          console.log(`[Nexmo] ${members.items.size} members in conversation: `);
          members.items.forEach((m: any) =>
            console.log(`[Nexmo] ${m.id}${m.id === conversation.me.id ? ' (us)' : ''}: ${m.state}`)
          );
        } else {
          console.warn('[Nexmo] No members appear in the conversation.');
        }
        this._conversation = conversation;
        this.emit(Events.JOINED_CONVERSATION, id);
        return conversation;
      } catch (e: any) {
        console.warn(`[Nexmo] Failed to retrieve conversation: ${e.message}
            ${e.stack}`);
        if (nexmoGetConversationRetriesLeft <= 0) {
          const message = `Exceeded max number of attempts to retrieve conversation`;
          void this._options.logEvent({
            message,
            clientEventType: ClientEventType.NEXMO,
            meta: {
              conversationId: this._conversation?.id,
              error: 'failed-to-retrieve-conversation',
            },
          });
          throw new Error(message);
        }
        console.log('[Nexmo] Will create a new login session before retrying...');
        await this.startNewSession();
        await attemptToRetrieveConversation();
      } finally {
        this._isGettingConversation = false;
      }
    };

    try {
      if (!this.isLoggedIn()) {
        await this.login();
      }
      return await attemptToRetrieveConversation();
    } catch (e: any) {
      console.error(`[Nexmo] Failed to retrieve Nexmo conversation: ${e.message}
          ${e.stack}`);
      throw e;
    }
  }

  // Refresh the conversation object from Nexmo
  // This is useful to poll for the latest media audio_settings to assert that we're muted.
  // The refreshed conversation will be stored in the instance variable.
  // Re-throws any errors returned by Nexmo.
  async refreshConversation(): Promise<void> {
    if (!this._conversation) {
      console.error(
        '[Nexmo] Cannot refresh non-existent conversation - call getConversation(id) before attempting to refresh. '
      );
      return;
    }
    try {
      this._conversation = await this._nexmoClientInstance!.application.getConversation(
        this._conversation.id
      );
    } catch (e: any) {
      console.error(`[Nexmo] Failed refresh Nexmo conversation: ${e.message}
          ${e.stack}`);
      throw e;
    }
  }

  // Fetch a new JWT and establish a new session every time
  async startNewSession(): Promise<void> {
    console.log('[Nexmo] Starting a new session...');
    await this.logout();
    await this.login();
  }

  // Throws an error if the conversation is not attached
  async attach({
    orgUuid,
    conversationUuid,
    muteMicByDefault,
    force,
    source,
    deviceId = '',
  }: NexmoClientAttachProps): Promise<void> {
    console.log(`[Nexmo] attach() called with conversationUuid: ${conversationUuid}`);
    console.log(
      `[Nexmo] Nexmo client requested to attach to conversation '${conversationUuid}' with mic '${
        muteMicByDefault ? 'disabled' : 'enabled'
      }' ...`
    );
    if (muteMicByDefault !== this._callShouldBeMuted) {
      this._callShouldBeMuted = muteMicByDefault;
    }
    if (!force) {
      if (this._isAttaching) {
        console.log(`[Nexmo] Already attaching to conversation, ignoring attach request.`);
        return;
      }
      // Since we login while the application is mounting, if the user attempts to attach to a conversation
      // before the login has completed, we should wait for the login to complete before continuing with
      // the attach process (e.g. when a user refreshes an active call page)
      if (this._isLoggingIn) {
        console.log(`[Nexmo] Awaiting login in progress before attaching to conversation...`);
        await this._loginPromise;
        console.log(`[Nexmo] Existing login completed.`);
      }
      if (this._isGettingConversation) {
        console.log(`[Nexmo] Already getting conversation, ignoring attach request.`);
        return;
      }
      if (this._conversation && this._conversation.id === conversationUuid) {
        console.log(
          `[Nexmo] Already attached to conversation '${conversationUuid}', ignoring attach request.`
        );
        return;
      }
    }
    console.log(
      `[Nexmo] Nexmo client service attaching to conversation '${conversationUuid}' as requested by '${source}'...`
    );
    this._previousMuteState = MuteState.UNKNOWN;
    this._previousEarmuffState = EarMuffState.UNKNOWN;
    this._orgUuid = orgUuid;
    this._isAttaching = true;
    this.emit(Events.JOINING_CONVERSATION, conversationUuid);
    window.performance.mark(PerformanceMarks.NEXMO_AUDIO_STARTED_CONNECTING);
    try {
      const { requestId, correlatedLog, correlatedWarn, correlatedError } = startCorrelatingLogs();
      await this.login();
      try {
        if (muteMicByDefault) {
          // Ask backend to join us to the conversation in the muted state
          await this._api.joinNexmoConversation(requestId, orgUuid, conversationUuid);
          const msg = `[Nexmo] Pre-joined conversation ${conversationUuid} in the muted state via the backend.`;
          correlatedLog(msg);
          console.log(msg);
        } else {
          const msg =
            '[Nexmo] Skipping pre-joining Nexmo conversation via the backend since this is a human voice call.';
          correlatedLog(msg);
          console.log(msg);
        }
      } catch (e: any) {
        // Non-fatal error - we will fallback on muting ourselves once joined
        const msg = `[Nexmo] Failed to pre-join conversation ${conversationUuid} in a muted state: ${
          e?.response?.data || e.message
        }`;
        correlatedWarn(msg);
        console.warn(msg);
      }
      await this.getConversation(orgUuid, conversationUuid);
      if (!this._conversation) {
        const msg = '[Nexmo] Failed to getConversation, cannot proceed with attaching';
        correlatedError(msg);
        console.error(msg);
        return;
      }
      this.attachEventListeners();
      const msg = `[Nexmo] Attaching media stream for conversation ${this._conversation.id}`;
      correlatedLog(msg);
      console.log(msg);
      await this._conversation.media.enable({
        audioConstraints: {
          autoGainControl: false,
          echoCancellation: true,
          noiseSuppression: false,
          deviceId: deviceId ?? this._options.deviceId ?? undefined,
        },
      });
      window.performance.mark(PerformanceMarks.NEXMO_AUDIO_COMPLETED_CONNECTING);

      // set initial mute state
      await this._conversation.media.mute(muteMicByDefault);
      if (!this._options.disablePolling) this.startPollingMuteState();
      window.performance.measure(
        PerformanceMeasures.TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO,
        PerformanceMarks.NEXMO_AUDIO_STARTED_CONNECTING,
        PerformanceMarks.NEXMO_AUDIO_COMPLETED_CONNECTING
      );
      const timeToLoadNexmo = window.performance.getEntriesByName(
        PerformanceMeasures.TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO
      )[0].duration;
      const message = `Time to complete connecting to Nexmo conversation from source '${source}': ${timeToLoadNexmo}ms`;
      console.log(`[Nexmo] Performance: ${message}`);
      void logEventToBigQuery({
        clientEventType: ClientEventType.PERFORMANCE_MEASUREMENT,
        message,
        meta: { timeToConnectToNexmoConversation: timeToLoadNexmo, source, conversationUuid },
      });
      // clean up
      window.performance.clearMeasures(PerformanceMeasures.TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO);
      window.performance.clearMarks(PerformanceMarks.NEXMO_AUDIO_STARTED_CONNECTING);
      window.performance.clearMarks(PerformanceMarks.NEXMO_AUDIO_COMPLETED_CONNECTING);
    } catch (e: any) {
      console.error(
        `[Nexmo] Failed to connect Nexmo media stream for conversation '${conversationUuid}' with muteMicByDefault set to '${muteMicByDefault}': ${JSON.stringify(
          e.message
        )}`
      );
      this.emit(Events.DISCONNECT_DETECTED);
      throw e;
    } finally {
      this._isAttaching = false;
    }
  }

  private assertEventMatchesThisConversationForRTCEvents(event: NXMEvent) {
    const bool = this._conversation?.me.id && event.from === this._conversation.me.id;
    if (!bool)
      throw new Error(
        `Received Nexmo RTC event from another member - event: ${JSON.stringify(
          // Avoid circular ref inside conversation object
          { ...event, conversation: event.conversation?.id },
          null,
          2
        )}`
      );
    return true;
  }

  private handleRtcAnswered(event: NXMEvent) {
    try {
      this.assertEventMatchesThisConversationForRTCEvents(event);
    } catch (e: any) {
      console.log(`[Nexmo] ${e.message}`);
      return;
    }
    console.log('[Nexmo] Detected RTC answer event for this member');
  }

  private async handleRtcHangup(event: NXMEvent) {
    try {
      this.assertEventMatchesThisConversationForRTCEvents(event);
    } catch (e: any) {
      console.log(`[Nexmo] ${e.message}`);
      return;
    }
    console.warn('[Nexmo] Detected RTC hangup event for this member...');
    // This could be caused by the call being hung-up on us, so we should assert
    // that the call still exists before attempting to reconnect to it
    try {
      await this.refreshConversation();
    } catch (e: any) {
      if (e.type === 'conversation:error:not-found') {
        console.log(
          `[Nexmo] Nexmo could not find conversation '${this._conversation?.id}', assuming it has been hung up and not attempting to reconnect.`
        );
        return;
      }
    }
    console.log(`[Nexmo] Nexmo conversation '${this._conversation?.id}' appears to still exist...`);
    this.emit(Events.DISCONNECT_DETECTED);
    if (this._orgUuid && this._conversation?.id) {
      const conversationUuid = this._conversation.id;
      console.log('[Nexmo] Attempting to recover from RTC hangup event by reconnecting...');
      this.emit(Events.ATTEMPTING_AUTO_RECONNECT);
      // We need to capture this value before we start a new session since it will be reset to false
      const muteMicByDefault = this._callShouldBeMuted;
      await this.startNewSession();
      try {
        await this.attach({
          orgUuid: this._orgUuid,
          conversationUuid,
          muteMicByDefault,
          force: true,
          source: NexmoAttachRequestSource.AUTO_RECONNECT,
        });
      } catch (e: any) {
        console.error(`[Nexmo] Failed to auto-reconnect after RTC hangup event: ${e.message}`);
      }
      void this._options.logEvent({
        message: `Detected rtc:hangup event and triggered auto-reconnect`,
        clientEventType: ClientEventType.NEXMO,
        meta: {
          conversationId: this._conversation?.id,
          event: 'auto-reconnect',
        },
      });
    }
  }

  // Throws an error
  private assertEventMatchesThisConversationWithMember(member: Member, event: NXMEvent) {
    const bool =
      this._conversation?.me.id &&
      member.memberId === this._conversation?.me.id &&
      event.conversation === this._conversation;
    if (!bool)
      throw new Error(
        `Received Nexmo event from another member - member: ${
          member.memberId
        }, event: ${JSON.stringify(
          // Avoid circular ref inside conversation object
          { ...event, conversation: event.conversation?.id },
          null,
          2
        )}`
      );
    return true;
  }

  private handleEarMuffEventFactory = (bool: boolean) => (member: Member, event: NXMEvent) => {
    try {
      this.assertEventMatchesThisConversationWithMember(member, event);
    } catch (e: any) {
      console.log(`[Nexmo] ${e.message}`);
      return;
    }
    if (bool) {
      console.log('[Nexmo] Nexmo client event emitted for earmuffs turned on (audio:earmuff:on)');
    } else {
      console.log('[Nexmo] Nexmo client event emitted for earmuffs turned off (audio:earmuff:off)');
    }
  };
  private readonly handleEarMuffOnEvent = this.handleEarMuffEventFactory(true);
  private readonly handleEarMuffOffEvent = this.handleEarMuffEventFactory(false);

  private handleMuteEventFactory = (bool: boolean) => (member: Member, event: NXMEvent) => {
    try {
      this.assertEventMatchesThisConversationWithMember(member, event);
    } catch (e: any) {
      console.log(`[Nexmo] ${e.message}`);
      return;
    }
    if (bool) {
      console.log('[Nexmo] Nexmo client event emitted for muting turned on (audio:mute:on)');
    } else {
      console.log('[Nexmo] Nexmo client event emitted for muting turned off (audio:mute:off)');
    }
  };
  private readonly handleMuteOnEvent = this.handleMuteEventFactory(true);
  private readonly handleMuteOffEvent = this.handleMuteEventFactory(false);

  private attachEventListeners() {
    // To avoid stacking up event listeners, remove them first
    this.removeEventListeners();
    if (!this._conversation) return;
    console.log('[Nexmo] Attaching event listeners for this conversation');
    this._conversation.on('rtc:answered', this.handleRtcAnswered);
    this._conversation.on('rtc:hangup', this.handleRtcHangup);
    this._conversation.on('audio:earmuff:on', this.handleEarMuffOnEvent);
    this._conversation.on('audio:earmuff:off', this.handleEarMuffOffEvent);
    this._conversation.on('audio:mute:on', this.handleMuteOnEvent);
    this._conversation.on('audio:mute:off', this.handleMuteOffEvent);
  }

  private removeEventListeners() {
    if (!this._conversation) return;
    console.log('[Nexmo] Removing event listeners for this conversation');
    this._conversation.off('rtc:answered', this.handleRtcAnswered);
    this._conversation.off('rtc:hangup', this.handleRtcHangup);
    this._conversation.off('audio:earmuff:on', this.handleEarMuffOnEvent);
    this._conversation.off('audio:earmuff:off', this.handleEarMuffOffEvent);
    this._conversation.off('audio:mute:on', this.handleMuteOnEvent);
    this._conversation.off('audio:mute:off', this.handleMuteOffEvent);
  }

  async maybeLeaveConversation() {
    if (this._conversation) {
      try {
        this.stopPollingMuteState();
        this.removeEventListeners();
        await this._conversation.media.disable();
        this.emit(Events.LEFT_CONVERSATION);
        console.log(`[Nexmo] Disabled media for Nexmo conversation.`);
      } catch (e: any) {
        console.error(`[Nexmo] Failed when leaving Nexmo conversation: ${e.message}
            ${e.stack}`);
        throw e;
      } finally {
        this._conversation = undefined;
      }
    }
  }

  /**
   * Earmuffing is an async operation (the Nexmo client performs a network transaction), so we
   * await the operation completing, and then emit a change event.
   * @param bool
   */
  async setEarmuffed(bool: boolean) {
    if (!this._conversation || !this._conversation.media) return;
    try {
      if (bool) {
        console.log('[Nexmo] Muting playback (earmuffing)');
      } else {
        console.log('[Nexmo] Unmuting playback (removing earmuffs)');
      }
      await this._conversation.me.earmuff(bool);
    } catch (e: any) {
      console.error(`[Nexmo] Failed ${bool ? '' : 'un'}earmuff attempt: ${e.message}
          ${e.stack}`);
      throw e;
    }
  }

  // Toggles transmitting audio via the microphone
  async setMuted(bool: boolean) {
    if (!this._conversation || !this._conversation.media) {
      console.warn(
        `[Nexmo] setMuted called with bool '${bool}', but no active nexmo conversation in progress.`
      );
      return;
    }
    try {
      if (bool) {
        console.log('[Nexmo] Disabling the microphone (muting)');
      } else {
        console.log('[Nexmo] Enabling the microphone (unmuting)');
      }
      this._callShouldBeMuted = bool;
      await this._conversation.me.mute(bool);
    } catch (e: any) {
      console.error(`[Nexmo] Failed ${bool ? '' : 'un'}mute attempt: ${e.message}
          ${e.stack}`);
      throw e;
    }
  }

  // Updates the audio constraints for the current conversation
  async updateAudioConstraints(constraints: MediaTrackConstraints) {
    if (!this._conversation || !this._conversation.media) {
      console.warn(
        `[Nexmo] updateAudioConstraints called with constraints '${JSON.stringify(
          constraints
        )}', but no active nexmo conversation in progress.`
      );
      return;
    }
    try {
      console.log(`[Nexmo] Updating audio constraints to ${JSON.stringify(constraints)}`);
      await this._conversation.media.updateAudioConstraints(constraints);
    } catch (e: any) {
      console.error(`[Nexmo] Failed to update audio constraints: ${e.message}
          ${e.stack}`);
      throw e;
    }
  }

  // DO NOT USE: This is a no-op as the nexmo-client sdk does not support updating
  // the output device. This method is here to maintain compatibility with the
  // VonageClientService interface.
  async updateOutputDevice(deviceId: string) {
    return;
  }
}

export default NexmoClientService;
