// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';

import { initDayjsPlugins } from '@infinitus/utils/dayjsConfig';

let didInit = false;

// dayjs has an internal guard against plugin re-init, but we might as well have one too
if (!didInit) {
  initDayjsPlugins(dayjs);
  didInit = true;
}

export default dayjs;
