import {
  RequeueReason,
  TaskFailReason,
  TaskType,
  HumanIntroReason,
} from '@infinitus/generated/frontend-common';
import { infinitusai } from '@infinitus/proto/pbjs';
import { NonNullableFields } from '@infinitus/types/utils';

let backendServerHost: string;
let hostname: string;
let protocol: string;

export const IS_DEV = process.env.NODE_ENV === 'development';

// Service worker import compatibility
try {
  backendServerHost = window.location.hostname;
  hostname = window.location.hostname.split('.').slice(-2).join('.');
  protocol = window.location.protocol.replace(':', '');
} catch (error) {
  /* eslint-disable no-restricted-globals */
  backendServerHost = self.location.hostname;
  hostname = self.location.hostname.split('.').slice(-2).join('.');
  protocol = self.location.protocol.replace(':', '');
  /* eslint-enable no-restricted-globals */
}

export const IS_CI = process.env.CI === 'true';
export const CURRENT_PROTOCOL = protocol;
export const IS_HTTPS = CURRENT_PROTOCOL === 'https';
export const WEBSOCKET_PROTOCOL = IS_HTTPS ? 'wss' : 'ws';

// The app is running via a shared Codespace port
export const IS_SHARED_CODESPACE = process.env.REACT_APP_SHARED_CODESPACE === 'TRUE';

export const FRONTEND_VERSION =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_BUILD_ENV === 'development'
    ? 'development'
    : process.env.REACT_APP_BUILD_VERSION
    ? process.env.REACT_APP_BUILD_VERSION
    : 'unknown';

let backendPort: number | undefined = parseInt(process.env.REACT_APP_PORT ?? '') || 3002;
if (IS_SHARED_CODESPACE) {
  backendPort = undefined;
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_BUILD_ENV === 'development') {
    backendPort = 8080;
  } else {
    backendPort = undefined;
  }
}
export const BACKEND_PORT = backendPort;

if (IS_SHARED_CODESPACE) {
  backendServerHost = `${process.env.REACT_APP_CODESPACE_NAME}-${process.env.REACT_APP_PORT}.preview.app.github.dev`;
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_BUILD_ENV === 'staging') {
    backendServerHost = `api-staging.infinitusai.com`;
  } else if (
    process.env.REACT_APP_BUILD_ENV === 'unstable' ||
    process.env.REACT_APP_BUILD_ENV === 'beta-unstable'
  ) {
    backendServerHost = `api-unstable.infinitusai.com`;
  } else if (process.env.REACT_APP_BUILD_ENV === 'dev-unstable') {
    backendServerHost = `api-unstable.infinitusai.dev`;
  } else if (process.env.REACT_APP_BUILD_ENV === 'experimental-dev') {
    backendServerHost = `api-experimental.infinitusai.dev`;
  } else if (process.env.REACT_APP_BUILD_ENV === 'development') {
    backendServerHost = 'localhost';
  } else if (
    process.env.REACT_APP_BUILD_ENV === 'production-fasttrack' ||
    process.env.REACT_APP_BUILD_ENV === 'beta'
  ) {
    backendServerHost = `fasttrack-api.infinitusai.com`;
  } else {
    backendServerHost = `api.infinitusai.com`;
  }
}
export const BACKEND_SERVER_HOST = backendServerHost;

let backendServerUrl = '/api';
if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_BUILD_ENV === 'development') {
    backendServerUrl = `http://${BACKEND_SERVER_HOST}:${BACKEND_PORT}/api`;
  } else {
    backendServerUrl = `https://${BACKEND_SERVER_HOST}`;
  }
}
export const BACKEND_SERVER_URL = backendServerUrl;
export const WEBSOCKET_PORT = 3110;

let backendWebsocketUrl = `${WEBSOCKET_PROTOCOL}://localhost:${BACKEND_PORT}/api`;
let presenceWebsocketUrl = `ws://localhost:${WEBSOCKET_PORT}`;
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_BUILD_ENV !== 'development') {
  backendWebsocketUrl = `wss://${BACKEND_SERVER_HOST}`;
  presenceWebsocketUrl = `wss://${BACKEND_SERVER_HOST}`;
} else if (IS_SHARED_CODESPACE) {
  backendWebsocketUrl = `wss://${BACKEND_SERVER_HOST}/api`;
  presenceWebsocketUrl = `wss://${BACKEND_SERVER_HOST}/api`;
}

export const BACKEND_WEBSOCKET_URL = backendWebsocketUrl;
export const PRESENCE_WEBSOCKET_URL = presenceWebsocketUrl;

export const DEFAULT_AVAILABLE_SEATS = 25;
export const DEFAULT_NUM_CALLS_PER_TASK = 7;
export const DEFAULT_WATCHDOG_INTERVAL = 18;

// keep in sync with github.com/infinitusai/absinthe/blob/develop/be/internal/pkg/task/bvinputs/visit_treatment.go#L19
export const DEFAULT_NUM_BUSINESS_DAYS_BUFFER_BEFORE_ADOT = 3;

type ReactBuildEnvironment = 'production' | 'staging' | 'unstable' | 'dev-unstable';

export function getOperatorPortalUrl(buildEnv?: ReactBuildEnvironment) {
  let operatorPortalUrl = `${CURRENT_PROTOCOL}://localhost:3002`;
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
    if (buildEnv === 'staging') {
      operatorPortalUrl = `https://portal-staging.${hostname}`;
    } else if (buildEnv === 'unstable' || buildEnv === 'dev-unstable') {
      operatorPortalUrl = `https://portal-dev.${hostname}`;
    } else {
      operatorPortalUrl = `https://portal.${hostname}`;
    }
  }
  return operatorPortalUrl;
}

export const OPERATOR_PORTAL_URL = getOperatorPortalUrl(
  process.env.REACT_APP_BUILD_ENV as ReactBuildEnvironment
);

let customerPortalUrl = 'http://localhost:3001';
if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_BUILD_ENV === 'staging') {
    customerPortalUrl = `https://customer-staging.${hostname}`;
  } else if (
    process.env.REACT_APP_BUILD_ENV === 'unstable' ||
    process.env.REACT_APP_BUILD_ENV === 'dev-unstable' ||
    process.env.REACT_APP_BUILD_ENV === 'experimental-dev'
  ) {
    customerPortalUrl = `https://customer-dev.${hostname}`;
  } else {
    customerPortalUrl = `https://customer.${hostname}`;
  }
}
export const CUSTOMER_PORTAL_URL = customerPortalUrl;

export const JIRA_BASE_URL = 'https://infinitusai.atlassian.net';

export const GCLOUD_PROJECT_NAME =
  process.env.NODE_ENV === 'development' ? 'infinitus-dev' : 'backend-232322';

export const TEST_ORG_NAMES = ['infinitus', 'infinitus_ivr_hold'];
export const TTS_VOICE_SETTINGS_DEFAULT: Required<
  NonNullableFields<
    Pick<infinitusai.be.ITTSSetting, 'ttsSpeed' | 'ttsPitch' | 'ttsVoice' | 'muteEva'>
  >
> = {
  ttsSpeed: 1.0,
  ttsPitch: -1.6,
  ttsVoice: infinitusai.be.TTSSetting.Voice.en_US_Wavenet_F,
  muteEva: false,
};

export enum CallState {
  UNKNOWN,
  IN_PROGRESS,
  COMPLETED,
}

export const activeRequeueReasons = [
  RequeueReason.REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK,
  RequeueReason.REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA,
  RequeueReason.REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY,
  RequeueReason.REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA,
  RequeueReason.REASON_AGENT_PROVIDED_UNRELIABLE_DATA,
  RequeueReason.REASON_AGENT_CANT_FIND_MEMBER,
  RequeueReason.REASON_AGENT_CANT_FIND_MEMBER_PLAN,
  RequeueReason.REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT,
  RequeueReason.REASON_PROVIDED_CORRECT_NUMBER_FOR_BV,
  RequeueReason.REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED,
  RequeueReason.REASON_PIE_ESCALATION_NEEDED,
  RequeueReason.REASON_INCORRECT_INPUT_INFORMATION,
  RequeueReason.REASON_MISSING_INPUT_INFORMATION,
  RequeueReason.REASON_IVR_NAVIGATION_FAILURE,
  RequeueReason.REASON_UNEXPLAINED_OUTBOUND_HANGUP,
  RequeueReason.REASON_OPERATOR_ERROR,
  RequeueReason.REASON_SYSTEM_ISSUE,
  RequeueReason.REASON_PAYER_SYSTEM_ISSUE,
  RequeueReason.REASON_PAYER_UNREACHABLE,
  RequeueReason.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD,
  RequeueReason.REASON_AGENT_AUTOMATION_HANGUP,
  RequeueReason.REASON_PROVIDER_IS_INACTIVE,
  RequeueReason.REASON_NO_BENEFITS_UNDER_THIS_PLAN,
  RequeueReason.REASON_UNABLE_TO_REVERIFY_BENEFITS,
  RequeueReason.REASON_OTHER,
] as const;

export type ActiveRequeueReason = (typeof activeRequeueReasons)[number];

export const DisabledFailReasons = [
  TaskFailReason.REASON_UNKNOWN,
  TaskFailReason.REASON_PATIENT_DECEASED,
  TaskFailReason.REASON_PLAN_TERMINATED,
  TaskFailReason.FASTTRACK_REASON_REDIAL,
  TaskFailReason.REASON_IVR_NAVIGATION_FAILURE,
  TaskFailReason.REASON_HOLD_NAVIGATION_FAILURE,
  TaskFailReason.REASON_AGENT_AUTOMATION_HANGUP,
  TaskFailReason.REASON_NO_OPERATOR_AVAILABLE,
  TaskFailReason.REASON_OTHER,
  TaskFailReason.REASON_PAST_DUE,
  TaskFailReason.REASON_PRESCRIBER_UNREACHABLE,
  TaskFailReason.REASON_TASK_EXPIRED,
  TaskFailReason.REASON_NO_OPERATOR_AVAILABLE,
] as const;

export type ActiveFailReason = Exclude<TaskFailReason, (typeof DisabledFailReasons)[number]>;

// This is the list of failure reasons shown to reviewers when they complete review
export const activeFailReasons: ActiveFailReason[] = [
  TaskFailReason.REASON_BAD_DATA,
  TaskFailReason.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD,
  TaskFailReason.REASON_CUSTOMER_CANCELED,
  TaskFailReason.REASON_INVALID_PLAN,
  TaskFailReason.REASON_MISSING_DATA,
  TaskFailReason.REASON_PAYER_REFUSES_DA,
  TaskFailReason.REASON_PAYER_REFUSES_THIRD_PARTY,
  TaskFailReason.REASON_PAYER_UNREACHABLE,
];

export type ProcessCallFieldIssuePrefixType = 'Incorrect' | 'Missing';
export const getProcessCallMissingInputFieldsOptions = (
  fieldIssuePrefix: ProcessCallFieldIssuePrefixType = 'Incorrect'
) => [
  `${fieldIssuePrefix} Admin Code`,
  `${fieldIssuePrefix} Group Number`,
  `${fieldIssuePrefix} Member First Name`,
  `${fieldIssuePrefix} Member Last Name`,
  `${fieldIssuePrefix} Member Date of Birth`,
  `${fieldIssuePrefix} Member ID`,
  `${fieldIssuePrefix} Member Address`,
  `${fieldIssuePrefix} Practice Name`,
  `${fieldIssuePrefix} Practice Address`,
  `${fieldIssuePrefix} Practice NPI`,
  `${fieldIssuePrefix} Practice Tax ID`,
  `${fieldIssuePrefix} Provider Name`,
  `${fieldIssuePrefix} Provider Address`,
  `${fieldIssuePrefix} Provider NPI`,
  `${fieldIssuePrefix} Provider Tax ID`,
  `${fieldIssuePrefix} SSN`,
  `${fieldIssuePrefix} PTAN`,
];

export enum PerformanceMarks {
  // When acknowledgement is received for an operator heartbeat websocket message
  OPERATOR_HEARTBEAT_SENT_VIA_WEBSOCKET = 'OPERATOR_HEARTBEAT_SENT_VIA_WEBSOCKET',
  // When the message is received from the websocket
  OPERATOR_SHOULD_JOIN_CALL_RECEIVED_VIA_WEBSOCKET = 'OPERATOR_SHOULD_JOIN_CALL_RECEIVED_VIA_WEBSOCKET',
  // When our app starts the history.push to route the operator to the call page
  OPERATOR_ROUTED_BY_JOIN_CALL_MESSAGE = 'OPERATOR_ROUTED_BY_JOIN_CALL_MESSAGE',
  // When the operator starts loading the call page React component
  OPERATOR_STARTED_LOADING_CALL_PAGE = 'OPERATOR_STARTED_LOADING_CALL_PAGE',
  // When the operator makes first attempt to notify the backend of presence via REST
  OPERATOR_INITIATING_RESTFUL_PRESENCE_REQUEST = 'OPERATOR_INITIATING_RESTFUL_PRESENCE_REQUEST',
  // When the operator completes their RESTful presence request
  OPERATOR_COMPLETED_RESTFUL_PRESENCE_REQUEST = 'OPERATOR_COMPLETED_RESTFUL_PRESENCE_REQUEST',
  // When the operator clicks a suggestion to deliver an utterance
  OPERATOR_CLICKED_SUGGESTION = 'OPERATOR_CLICKED_SUGGESTION',
  // When the executeAction call completes
  EXECUTE_ACTION_API_CALL_COMPLETED = 'EXECUTE_ACTION_API_CALL_COMPLETED',
  // When Nexmo starts connecting to the conversation
  NEXMO_AUDIO_STARTED_CONNECTING = 'NEXMO_AUDIO_STARTED_CONNECTING',
  // When Nexmo completes its connection successfully
  NEXMO_AUDIO_COMPLETED_CONNECTING = 'NEXMO_AUDIO_COMPLETED_CONNECTING',
}

export enum PerformanceMeasures {
  TIME_TO_HEARTBEAT_ACKNOWLEDGED = 'TIME_TO_HEARTBEAT_ACKNOWLEDGED',
  TIME_TO_RESPOND_TO_JOIN_CALL_WEBSOCKET_MESSAGE = 'TIME_TO_RESPOND_TO_JOIN_CALL_WEBSOCKET_MESSAGE',
  TIME_TO_START_LOADING_CALL_PAGE_AFTER_ROUTING = 'TIME_TO_START_LOADING_CALL_PAGE_AFTER_ROUTING',
  TIME_TO_INITIATE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE = 'TIME_TO_ISSUE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE,',
  TIME_TO_COMPLETE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE = 'TIME_TO_COMPLETE_FIRST_PRESENCE_REQUEST_AFTER_RECEIVING_JOIN_CALL_MESSAGE',
  TIME_TO_COMPLETE_EXECUTE_ACTION = 'TIME_TO_COMPLETE_EXECUTE_ACTION',
  TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO = 'TIME_TO_COMPLETE_CONNECTING_NEXMO_AUDIO',
}

// Process Call timeout milliseconds controls the amount of time the operator has to process a call
// before the exclusive lock expires. This is to prevent the multiple operators from concurrently processing the same call.
export const PROCESS_CALL_TIMEOUT_MILLIS = 1000 * 60 * 5; // 5 minutes

export const shouldGetFlexibleTaskInputsByTaskType = (taskType: TaskType) =>
  ![
    TaskType.TASK_TYPE_FULL_BI,
    TaskType.TASK_TYPE_PBM_BV,
    TaskType.TASK_TYPE_PBM_DISCOVERY,
  ].includes(taskType);

// IncidentType UUID for automation and automatically filed autopilot incidents
export const AI_INCIDENT_TYPE_UUID =
  process.env.NODE_ENV === 'development'
    ? '4719c237-8bf8-4cd0-965e-cbe543ecccbe'
    : '738d8a67-06a9-4815-9c97-9bf983df4290';

export const relevantHumanIntroReasons = [
  HumanIntroReason.HUMAN_INTRO_REASON_VOICE_PAYER_REFUSAL,
  HumanIntroReason.HUMAN_INTRO_REASON_OFFICIAL_PAYER_SOP,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_SUPERVISOR,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_PA_DEPARTMENT,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_TPA,
  HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_OTHER,
  HumanIntroReason.HUMAN_INTRO_REASON_NON_ESCALATION_OTHER,
] as const;

export const isCypress = () => {
  return typeof window !== 'undefined' && (window as any).Cypress;
};
