import { createContext, Dispatch } from 'react';

import { infinitusai } from '@infinitus/proto/pbjs';

import { HeartbeatMessageType } from './types';

interface ContextProps {
  isCallBeingRequeuedSemaphore: boolean;
  microReviewData: infinitusai.be.IOperatorRouteToMicroReviewMessage | undefined;
  operatorReadyToJoinCalls: boolean;
  operatorsInQueue: null | string[];
  resetPageHeartbeat: () => void;
  sendHeartbeat: () => Promise<void>;
  setIsCallBeingRequeuedSemaphore: (value: boolean) => void;
  setOperatorReadyToJoinCalls: Dispatch<React.SetStateAction<boolean>>;
  setPageHeartbeat: (
    heartbeatMessageType: HeartbeatMessageType,
    heartbeatMessage: infinitusai.be.IHeartbeatMessageFromClient
  ) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <OperatorPresenceProvider>.');
};

const OperatorPresenceContext = createContext<ContextProps>({
  operatorReadyToJoinCalls: false,
  operatorsInQueue: null,
  setPageHeartbeat: stub,
  resetPageHeartbeat: stub,
  setOperatorReadyToJoinCalls: stub,
  sendHeartbeat: stub,
  isCallBeingRequeuedSemaphore: false,
  setIsCallBeingRequeuedSemaphore: stub,
  microReviewData: undefined,
});

export default OperatorPresenceContext;
