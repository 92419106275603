import { UserOrg } from '@infinitusai/auth';
import Long from 'long';

import { infinitusai } from '@infinitus/proto/pbjs';
import { CallState } from '@infinitus/utils/constants';

import { SuggestionsViewMode } from './localStorage';

export const castPossibleLongToNumber = (possibleLong: Long.Long | number): number => {
  if (Long.isLong(possibleLong)) return (possibleLong as Long).toNumber();
  return possibleLong as number;
};

const STRIP_SSML_REGEX = /<\/?[^>]+(>|$)/g;

export const stripSSML = (text: string) => text.replace(STRIP_SSML_REGEX, '').trim();

export * from './url';

export const getCallCompletionState = (callProto?: {
  audioEndMillis: number;
  audioStartMillis: number;
}): CallState => {
  if (!callProto) return CallState.UNKNOWN;
  const startMillis = Long.fromValue(callProto.audioStartMillis);
  const endMillis = Long.fromValue(callProto.audioEndMillis);
  if (startMillis.lessThanOrEqual(0) || endMillis.lessThan(0)) return CallState.UNKNOWN;
  return endMillis.isZero() ? CallState.IN_PROGRESS : CallState.COMPLETED;
};

export const isRecordingReady = (callProto?: infinitusai.be.CallDoc): boolean => {
  // If callProto is defined but callProto.recordingReady is undefined, we treat it as recordingReady as
  // new calls should either have recordingReady === false || recordingReady === true.
  return callProto
    ? callProto.recordingReady === true || callProto.recordingReady === undefined
    : false;
};

// Make JSON stringify deterministic by sorting keys - even the nested ones.
export const JSONStringifyOrder = (obj: any, space: number = 0) => {
  var allKeys: string[] = [];
  var seen: { [key: string]: boolean } = {};
  JSON.stringify(obj, function (key, value) {
    if (!(key in seen)) {
      allKeys.push(key);
      seen[key] = true;
    }
    return value;
  });
  allKeys.sort();
  return JSON.stringify(obj, allKeys, space);
};

// getSuggestionViewMode takes a value and returns if the suggestion view mode is Reviewer or Operator
export const getSuggestionViewMode = (value: string) => {
  return value === SuggestionsViewMode.REVIEW ? 'Reviewer' : 'Operator';
};

type EnvironmentName = 'development' | 'unstable' | 'staging' | 'production';

export const getEnvironmentName: () => EnvironmentName = () => {
  if (window.location.href.includes('portal-dev')) {
    return 'unstable';
  }

  if (window.location.href.includes('staging')) {
    return 'staging';
  }

  if (window.location.href.includes('portal.')) {
    return 'production';
  }

  // Could include localhost, codespaces domains, etc.
  return 'development';
};

export function randInt(pMin: number, pMax: number) {
  pMin = Math.round(pMin);
  pMax = Math.round(pMax);
  const hi = Math.max(pMin, pMax);
  const lo = Math.min(pMin, pMax);
  return Math.floor(Math.random() * (hi + 1 - lo) + lo);
}

export function pickRandom(list: any[]) {
  return list[randInt(0, list.length - 1)];
}

export function getOrgNameFromUuid(orgs: UserOrg[], orgUuid: string) {
  const userOrgs = orgs || {};
  const org = Object.values(userOrgs).find((org) => org.uuid === orgUuid);
  return org?.name ?? '';
}

export function getOrgInfo(orgs: UserOrg[], orgName: string) {
  const userOrgs = orgs || {};
  const defaultMembership: UserOrg = {
    uuid: '',
    name: '',
    displayName: '',
    imageUrl: '',
    internal: false,
    live: false,
    test: false,
    demo: false,
  };
  const org = userOrgs.filter((org) => org.name === orgName)[0] || defaultMembership;

  return org;
}

export function createInverseMap<K, V>(map: Map<K, V>): Map<V, K> {
  const inverseMap = new Map();
  for (const [key, value] of map) {
    inverseMap.set(value, key);
  }
  return inverseMap;
}
