import * as React from 'react';

import { AudioDeviceContext } from './AudioDeviceContext';
import { AudioDeviceContextInterface } from './types';

/**
 * The hook to use the audio device context.
 */
export const useAudioDevice = (): AudioDeviceContextInterface =>
  React.useContext(AudioDeviceContext) as AudioDeviceContextInterface;
