import * as React from 'react';

import { initialState, AudioDeviceContextInterface } from './types';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AudioDeviceProvider>.');
};

const initialContext = {
  ...initialState,
  selectDevice: stub,
};

/**
 * The Audio Device Context
 */
export const AudioDeviceContext = React.createContext<AudioDeviceContextInterface>(initialContext);
